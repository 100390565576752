import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth/auth.guard';
import { loginGuard } from './core/guards/login/login.guard';
import { homeResolver } from './core/resolvers/home/home.resolver';
import { tokenResolver } from './core/resolvers/token/token.resolver';
import { userResolver } from './core/resolvers/user/user.resolver';
import { AboutComponent } from './pages/about/about.component';
import { CartComponent } from './pages/cart/cart.component';
import { CatalogsComponent } from './pages/catalogs/catalogs.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DealerComponent } from './pages/dealer/dealer.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ProductsComponent } from './pages/products/products.component';
import { RegisterUserComponent } from './pages/register-user/register-user.component';
import { SacComponent } from './pages/sac/sac.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AddressCustomersComponent } from './shared/components/customer-area/address-customers/address-customers.component';
import { CustomerAreaComponent } from './shared/components/customer-area/customer-area.component';
import { DataCustomersComponent } from './shared/components/customer-area/data-customers/data-customers.component';
import { OrdersCustomersComponent } from './shared/components/customer-area/orders-customers/orders-customers.component';
import { ModelsProductsComponent } from './shared/components/models-products/models-products.component';
import { SubCategoriesComponent } from './shared/components/sub-categories/sub-categories.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: { data: homeResolver, token: tokenResolver, user: userResolver },
  },
  {
    path: 'quem-somos',
    component: AboutComponent,
    resolve: {
      token: tokenResolver,
      user: userResolver,
    },
  },

  {
    path: 'nova-senha/:token/:email',
    component: ForgotComponent,
  },
  {
    path: 'novo-cadastro/:email',
    component: RegisterUserComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'fale-conosco',
    component: ContactComponent,
  },
  {
    path: 'sac',
    component: SacComponent,
  },

  {
    path: 'seja-um-revendedor',
    component: DealerComponent,
  },
  {
    path: 'catalogos',
    component: CatalogsComponent,
  },
  {
    path: 'politicas-trocas-cancelamentos',
    component: PoliciesComponent,
  },
  {
    path: 'termos-condicoes-uso',
    component: TermsComponent,
  },
  {
    path: 'faq',
    component: FaqPageComponent,
  },
  {
    path: 'aviso-de-privacidade',
    component: PrivacyComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'carrinho',
    component: CartComponent,
    resolve: {
      token: tokenResolver,
      user: userResolver,
    },
  },

  {
    path: 'carrinho/:idCart',
    component: CartComponent,
    resolve: {
      token: tokenResolver,
      user: userResolver,
    },
  },
  {
    path: 'produtos/:querySearch',
    component: ProductsComponent,
  },
  {
    path: 'produtos-em-oferta',
    component: SubCategoriesComponent,
  },
  {
    path: 'pagamento',
    component: PaymentComponent,
    canActivate: [authGuard],
    resolve: {
      token: tokenResolver,
      user: userResolver,
    },
  },
  {
    path: 'area-do-cliente',
    component: CustomerAreaComponent,
    canActivate: [authGuard],
    resolve: {
      token: tokenResolver,
      user: userResolver,
    },
    children: [
      {
        path: 'meus-pedidos',
        component: OrdersCustomersComponent,
      },
      {
        path: 'meus-dados',
        component: DataCustomersComponent,
      },
      {
        path: 'meu-endereco',
        component: AddressCustomersComponent,
      },
    ],
  },
  {
    path: 'acessorios-persianas',
    component: SubCategoriesComponent,

    children: [
      {
        path: ':model',
        component: ModelsProductsComponent,

        children: [
          {
            path: ':sku/:name',
            component: ProductsComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'ambientes',
    component: SubCategoriesComponent,

    children: [
      {
        path: ':model',
        component: ModelsProductsComponent,

        children: [
          {
            path: ':sku/:name',
            component: ProductsComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'double-vision',
    component: SubCategoriesComponent,

    children: [
      {
        path: ':model',
        component: ModelsProductsComponent,

        children: [
          {
            path: ':sku/:name',
            component: ProductsComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'rolo',
    component: SubCategoriesComponent,

    children: [
      {
        path: ':model',
        component: ModelsProductsComponent,

        children: [
          {
            path: ':sku/:name',
            component: ProductsComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'romana',
    component: SubCategoriesComponent,

    children: [
      {
        path: ':model',
        component: ModelsProductsComponent,

        children: [
          {
            path: ':sku/:name',
            component: ProductsComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'produtos',
    redirectTo: 'coberturas-em-policarbonato/policarbonato-alveolar',
    pathMatch: 'full',
  },
  {
    path: 'pagina-nao-encontrada',
    component: NotFoundComponent,
  },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];
