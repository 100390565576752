import InfoSubCategory from '../app/info-sub-category';

export default class ProductColorBalance {
  id?: number;
  category?: string;
  color?: string;
  name?: string;
  stockBalance?: number;
  urlFirstImage?: string;
  url?: string;
  urlSimple?: string;

  fromJson(product: any, subCategory: InfoSubCategory) {
    if (product) {
      this.id = product.id;
      this.category = product.category;
      this.color = product.color;
      this.name = product.name;
      this.stockBalance = product.stockBalance;
      this.url = this.getUrlProduct(this, subCategory);
      this.urlSimple = this.getUrlSimpleProduct(this);
      this.urlFirstImage = product.urlFirstImage;
    }
  }

  getUrlProduct(product: ProductColorBalance, subCategory: InfoSubCategory) {
    let name = product?.name?.toLowerCase()?.replace(/ /g, '-');
    name = name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    name = name
      ?.replace(/% ?/g, '')
      ?.replaceAll('/', '')
      ?.replaceAll('(', '')
      ?.replaceAll(')', '')
      ?.replaceAll(',', '-')
      ?.replaceAll('.', '-')
      ?.replace(/\---/gi, '-')
      ?.replace(/\--/gi, '-');
    return `${subCategory?.path}/${product.id}/${name}`;
  }

  getUrlSimpleProduct(product: ProductColorBalance) {
    let name = product?.name?.toLowerCase()?.replace(/ /g, '-');
    name = name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    name = name
      ?.replace(/% ?/g, '')
      ?.replaceAll('/', '')
      ?.replaceAll('(', '')
      ?.replaceAll(')', '')
      ?.replaceAll(',', '-')
      ?.replaceAll('.', '-')
      ?.replace(/\---/gi, '-')
      ?.replace(/\--/gi, '-');
    return `${product.id}/${name}`;
  }
}
