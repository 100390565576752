import { environment } from '../../../../environments/environment';
import { InterestPercentageEnum } from '../../../services/payment/payment.service';
import { ArrayUtils } from '../../utils/array-utils';
import { ValueUtils } from '../../utils/value';
import { AbstractInfoEnum } from '../app/abstract-info-enum';
import { InfoCategory } from '../app/info-category';
import { SubCategoriesEnum } from '../app/info-enum';
import InfoSubCategory from '../app/info-sub-category';
import AttachmentProductLink from '../attachment/attachment-product-link';
import QuoteList from '../freight/quote-list';
import GroupedSkusView from '../grouping/grouped-skus-view';
import InformationSection from './information-section';
import ProductColorBalance from './product-color-balance';
import ProductHubDetails from './product-hub-details';
import Volume from './volume';

export class FilterProductsShop {
  public filterSelected?: AbstractInfoEnum;
  public name?: string;
  public title?: string;
  public values?: FilterValueProductsShop[];
  public visible = true;
}

export class FilterValueProductsShop {
  public description?: string;
  public id?: string;
  public title?: string;
}
export class FilterAttributes {
  public actuationSide?: AbstractInfoEnum;
  public actuationType?: AbstractInfoEnum;
  public aluminumWallThickness?: AbstractInfoEnum;
  public brand?: AbstractInfoEnum;
  public capabilitie?: AbstractInfoEnum;
  public color?: AbstractInfoEnum;
  public colorCode?: AbstractInfoEnum;
  public fabricComposition?: AbstractInfoEnum;
  public finish?: AbstractInfoEnum;
  public heightThickness?: AbstractInfoEnum;
  public length?: AbstractInfoEnum;
  public model?: AbstractInfoEnum;
  public potencie?: AbstractInfoEnum;
  public speed?: AbstractInfoEnum;
  public unitsCommercialize?: AbstractInfoEnum;
  public voltage?: AbstractInfoEnum;
  public width?: AbstractInfoEnum;
}

export class ConfigSubCategories {
  public persiana?: Array<InfoCategory>;
  public subCategorySelected: InfoSubCategory | undefined;
  public modelSelected: AbstractInfoEnum | undefined;
  public productsOnOffer: boolean = false;
  public productsQuerySearch: string | undefined;
}

export class Product {
  activeOnHub = false;
  actuationSide?: string;
  actuationSideDescription?: string;
  actuationType?: string;
  actuationTypeDescription?: string;
  aluminumWallThickness?: string;
  aluminumWallThicknessDescription?: string;
  amountFixation?: number;
  amountRafters?: number;
  brand?: string;
  brandDescription?: string;
  canBeGeneratedWithStockZero = false;
  category?: string;
  categoryDescription?: string;
  capacity?: string;
  capacityDescription?: string;
  color?: string;
  colorDescription?: string;
  coverPhoto?: string;
  daysOnlyOrder?: number;
  description?: string;
  dimensions?: string;
  discount?: number;
  enabled = false;
  fabricComposition?: string;
  fabricCompositionDescription?: string;
  featuredBanner?: string;
  finish?: string;
  finishDescription?: string;
  groupedSkus?: Array<Product>;
  groupedSkusView?: GroupedSkusView;
  groupAttributeProduct?: string;
  groupMainProduct?: boolean;
  gtin?: string;
  heightThickness?: string;
  heightThicknessDescription?: string;
  id?: number;
  informationsSection?: Array<InformationSection>;
  lastSincronizedHubDate?: string;
  length?: string;
  lengthDescription?: string;
  mainPhotoS3Url?: string;
  mainVariation?: boolean;
  model?: string;
  modelDescription?: string;
  name?: string;
  nbm?: string;
  origin?: string;
  originDescription?: string;
  packingWidth?: number;
  packingLength?: number;
  packingWeight?: number;
  packingHeightThickness?: number;
  photos?: Array<AttachmentProductLink>;
  potency?: string;
  potencyDescription?: string;
  productPerSquareMeter = false;
  productType?: number;
  productHubDetails?: ProductHubDetails;
  productsColorBalance?: Array<ProductColorBalance>;
  productsIds?: Array<number>;
  qtdMaxPiecesPerVolume?: number;
  qtd = 1;
  quoteList?: QuoteList;
  stockBalance = 0;
  similarProduct?: number;
  speed?: string;
  speedDescription?: string;
  subCategory?: string;
  subCategoryDescription?: string;
  synchronizedWithActiveHub = false;
  unitCommercialize?: string;
  unitCommercializeDescription?: string;
  url?: string;
  urlSimple?: string;
  validationMessages?: string[];
  value?: number;
  valueString?: string;
  valueInterestFree?: string;
  valueInstallment?: string;
  valueInstallmentWithoutJuros?: string;
  valueWithDiscount?: number;
  valueWithCashDiscount?: number;
  videoUrl?: string;
  voltage?: string;
  voltageDescription?: string;
  volumes?: Array<Volume>;
  warrantyTime?: number;
  warrantyText?: string;
  weight?: string;
  width?: string;
  widthDescription?: string;

  async getAllDescription(
    product: Product,
    category: InfoCategory,
    subCategory: InfoSubCategory
  ) {
    product.categoryDescription = category?.description;
    product.subCategoryDescription = subCategory?.description;

    let info = subCategory?.heightThickness.find(
      (item) => item.id === product?.heightThickness
    );
    if (info) {
      product.heightThicknessDescription = info?.description;
    }

    info = subCategory.widths.find((item) => item?.id === product?.width);
    if (info) {
      product.widthDescription = info?.description;
    }

    info = subCategory.lengths.find((item) => item?.id === product?.length);
    if (info) {
      product.lengthDescription = info?.description;
    }

    info = subCategory.colors.find((item) => item?.id === product?.color);
    if (info) {
      product.colorDescription = info?.description;
    }

    info = subCategory.models.find((item) => item?.id === product?.model);
    if (info) {
      product.modelDescription = info?.description;
    }

    if (subCategory.finishes) {
      info = subCategory.finishes.find((item) => item?.id === product?.finish);
      if (info) {
        product.finishDescription = info?.description;
      }
    }

    if (subCategory.aluminumWallThickness) {
      info = subCategory.aluminumWallThickness.find(
        (item) => item.id === product?.aluminumWallThickness
      );
      if (info) {
        product.aluminumWallThicknessDescription = info?.description;
      }
    }

    if (subCategory.brands) {
      info = subCategory.brands.find((item) => item?.id === product?.brand);
      if (info) {
        product.brandDescription = info?.description;
      }
    }

    if (subCategory.unitsCommercialize) {
      info = subCategory?.unitsCommercialize?.find(
        (item) => item?.id === product?.unitCommercialize
      );
      if (info) {
        product.unitCommercializeDescription = info?.description;
      }
    }

    if (subCategory.capabilities) {
      info = subCategory?.capabilities.find(
        (item) => item.id === product?.capacity
      );
      if (info) {
        product.capacityDescription = info.description;
      }
    }

    if (subCategory.speeds) {
      info = subCategory.speeds.find((item) => item.id === product.speed);
      if (info) {
        product.speedDescription = info.description;
      }
    }

    if (subCategory.potencies) {
      info = subCategory.potencies.find((item) => item.id === product.potency);
      if (info) {
        product.potencyDescription = info.description;
      }
    }

    if (subCategory.voltages) {
      info = subCategory.voltages.find((item) => item.id === product.voltage);
      if (info) {
        product.voltageDescription = info.description;
      }
    }

    const heightThickness = product.heightThicknessDescription
      ? product.heightThicknessDescription
      : ' | ';
    const width = product.widthDescription ? product.widthDescription : ' | ';
    const length = product.lengthDescription
      ? product.lengthDescription
      : ' | ';
    product.dimensions = '';
    if (
      product.heightThicknessDescription ||
      product.widthDescription ||
      product.lengthDescription
    ) {
      product.dimensions = heightThickness + ' - ' + width + ' x ' + length;
    }
  }

  addVideoInSession(product: Product) {
    if (product.videoUrl) {
      const session = new InformationSection();
      session.id = 99;
      session.title = 'Vídeo';
      session.description = product.videoUrl;
      session.orderDisplay = product?.informationsSection?.length;
      product?.informationsSection?.push(session);
    }
  }

  isPersiana() {
    return (
      this.subCategory === SubCategoriesEnum.DOUBLE_VISION ||
      this.subCategory === SubCategoriesEnum.ROMANA ||
      this.subCategory === SubCategoriesEnum.ROLO
    );
  }

  isPersianaRomana() {
    return this.subCategory === SubCategoriesEnum.ROMANA;
  }

  isGrouped() {
    return this.groupedSkus && this.groupedSkus?.length > 0;
  }

  async fromJson(product: any, persianas: InfoCategory) {
    if (product != null) {
      this.amountFixation = product.amountFixation;
      this.amountRafters = product.amountRafters;
      this.id = product.id;
      this.productType = product.productType;
      this.name = product.name;
      this.description = product.description;
      this.category = product.category;
      this.categoryDescription = product.categoryDescription;
      this.subCategory = product.subCategory;
      this.subCategoryDescription = product.subCategoryDescription;
      this.color = product.color;
      this.colorDescription = product.colorDescription;
      this.finish = product.finish;
      this.aluminumWallThickness = product.aluminumWallThickness;
      this.aluminumWallThicknessDescription =
        product.aluminumWallThicknessDescription;
      this.width = product.width;
      this.widthDescription = product.widthDescription;
      this.length = product.length;
      this.lengthDescription = product.lengthDescription;
      this.weight = product.weight;
      this.brand = product.brand;
      this.brandDescription = product.brandDescription;
      this.qtdMaxPiecesPerVolume = product.qtdMaxPiecesPerVolume;
      this.stockBalance = product.stockBalance || 0;
      this.enabled = product.enabled;
      this.unitCommercialize = product.unitCommercialize;
      this.unitCommercializeDescription = product.unitCommercializeDescription;
      this.heightThickness = product.heightThickness;
      this.heightThicknessDescription = product.heightThicknessDescription;
      this.canBeGeneratedWithStockZero = product.canBeGeneratedWithStockZero;
      this.daysOnlyOrder = product.daysOnlyOrder;
      this.packingHeightThickness = product.packingHeightThickness;
      this.packingWeight = product.packingWeight;
      this.packingWidth = product.packingWidth;
      this.packingLength = product.packingLength;
      this.productHubDetails = Object.assign(
        new ProductHubDetails(),
        product.productHubDetails
      );
      this.nbm = product.nbm;
      this.origin = product.origin;
      this.originDescription = product.originDescription;
      this.model = product.model;
      this.modelDescription = product.modelDescription;
      this.mainPhotoS3Url = product.mainPhotoS3Url;
      this.mainVariation = product.mainVariation;
      this.videoUrl = product.videoUrl;
      this.warrantyTime = product.warrantyTime;
      this.warrantyText = product.warrantyText;
      this.discount = product.discount;
      this.featuredBanner = product.featuredBanner;
      this.value = product.value;
      this.synchronizedWithActiveHub = product.synchronizedWithActiveHub;
      this.lastSincronizedHubDate = product.lastSincronizedHubDate;
      this.gtin = product.gtin;
      this.activeOnHub = product.activeOnHub;
      this.similarProduct = product.similarProduct;
      this.capacity = product.capacity;
      this.potency = product.potency;
      this.speed = product.speed;
      this.voltage = product.voltage;
      this.actuationSide = product.actuationSide;
      this.actuationType = product.actuationType;
      this.fabricComposition = product.fabricComposition;
      this.groupMainProduct = product.groupMainProduct;
      this.groupAttributeProduct = product.groupAttributeProduct;
      this.productPerSquareMeter = product.productPerSquareMeter;

      const subCategory: InfoSubCategory =
        persianas?.subCategories?.find(
          (item) => item.id === product?.subCategory
        ) || new InfoSubCategory();

      if (product.photos != null) {
        let newPhoto: AttachmentProductLink;
        this.photos = [];
        product.photos.forEach((item: any) => {
          newPhoto = new AttachmentProductLink();
          newPhoto?.fromJson(item);
          this.photos?.push(newPhoto);
        });
        ArrayUtils.sort(this.photos, 'orderDisplay');
        this.coverPhoto = this.photos[0].url;
      }

      if (product?.productsColorBalance != null) {
        let newProductColorBalance: ProductColorBalance;
        this.productsColorBalance = [];
        product?.productsColorBalance?.forEach((item: any) => {
          newProductColorBalance = new ProductColorBalance();
          newProductColorBalance.fromJson(item, subCategory);
          this.productsColorBalance?.push(newProductColorBalance);
        });
      }

      if (product?.informationsSection != null) {
        let newSection: InformationSection;
        this.informationsSection = [];
        product?.informationsSection?.forEach((item: any) => {
          newSection = new InformationSection();
          newSection.fromJson(item);
          this.informationsSection?.push(newSection);
        });
        ArrayUtils.sort(this.informationsSection, 'orderDisplay');
      }

      if (product?.volumes != null) {
        let newVolumes: Volume;
        this.volumes = [];
        product?.volumes?.forEach((item: any) => {
          newVolumes = new Volume();
          newVolumes.fromJson(item);
          this.volumes?.push(newVolumes);
        });
      }

      this.url = this.getUrlProduct(this);
      this.urlSimple = this.getUrlSimpleProduct(this);
      this.updateValues();
      this.getAllDescription(this, persianas, subCategory);
      this.description = this.getDescription();
      this.addVideoInSession(this);

      if (product.groupedSkus != null) {
        let newP: Product;
        this.groupedSkus = [];
        product.groupedSkus.forEach((item: any) => {
          item.subCategory = this.subCategory;
          newP = new Product();
          newP.fromJson(item, persianas);
          newP.color = newP?.color?.toLowerCase();
          this.groupedSkus?.push(newP);
        });

        this.groupedSkusView = new GroupedSkusView();
        this.groupedSkusView.mainSKU = this.groupedSkus
          .filter((p) => p.groupMainProduct)
          .slice()
          .shift();
        this.groupedSkusView.childrenSKUs = this.groupedSkus.filter(
          (p) => !p.groupMainProduct
        );
      }
    }
  }

  updateValues() {
    this.valueWithDiscount = this.getValueWithDiscount(this);
    this.valueInterestFree = this.installmentFormatInterestFree(this);
    this.valueWithCashDiscount = this.getValueWithCashDiscount(this);
    this.valueInstallment = this.getInstallmentValue(this);
    this.valueInstallmentWithoutJuros =
      this.getInstallmentWithoutJurosValue(this);
  }

  getDescription() {
    if (this.isKit()) {
      return this.getDescriptionKit();
    }
    return this.getDescriptionProduct();
  }

  isKit(): boolean {
    return this.productType === 2;
  }

  getDescriptionProduct() {
    const brand = this.brandDescription
      ? `Produto da marca ${this.brandDescription}`
      : ``;
    const width = this.widthDescription
      ? `com ${this.widthDescription} de largura`
      : ``;
    const length = this.lengthDescription
      ? `por ${this.lengthDescription} de comprimento`
      : ``;
    const heightThickness = this.heightThicknessDescription
      ? `e ${this.heightThicknessDescription} de altura/espessura`
      : ``;
    const color = this.colorDescription
      ? `na cor ${this.colorDescription}`
      : ``;
    const weight = this.weight ? `com peso de ${this.weight} kg` : ``;
    const finish = this.finishDescription
      ? `e acabamento ${this.finishDescription}`
      : ``;
    return `${brand} ${width} ${length} ${heightThickness} ${color} ${weight} ${finish} - SKU ${this.id}`;
  }

  getDescriptionKit() {
    const name = `Kit pronto para ${this.name}, `;
    const fixation = `com ${this.amountFixation} pontos de fixação. `;
    const info = `Para que possa aplica-lo, a sua estrutura deve seguir o padrão de: `;
    const width = `Largura: ${this.widthDescription} x `;
    const length = `${this.lengthDescription} Avanço/Caída da água. `;
    const rafters = `Quantidade de cáibros: ${this.amountRafters}. `;
    const weight = `O peso total deste kit é de ${this.weight} kg`;
    return `${name}${fixation}${info}${width}${length}${rafters}${weight} - SKU ${this.id}`;
  }

  getInstallmentValue(product: Product) {
    if (product) {
      const installments = this.getInstallments(product.value || 0);
      const valueFormat = ValueUtils.currency(
        installments[installments.length - 1]
      );
      return `${installments.length}x de ${valueFormat}`;
    }
    return '';
  }

  getInstallmentWithoutJurosValue(product: Product) {
    const value = product.value || 0;
    const installments = this.getTotalInstallments(value, true);
    const valueInstallmentFormat = ValueUtils.currency(value / installments);
    return `${installments}x de ${valueInstallmentFormat} sem juros`;
  }

  getValueWithCashDiscount(product: Product): number {
    if (product !== undefined) {
      let value = product.value || 0;
      let cashDiscount = environment.cashDiscount || 0;
      if (cashDiscount > 0) {
        value = value - (value * cashDiscount) / 100;
      }
      return value;
    } else {
      return 0;
    }
  }

  installmentFormatInterestFree(product: Product): string {
    const value = product.value || 0;
    const installments = this.getTotalInstallments(value, true);
    const valueInstallmentFormat = ValueUtils.currency(value / installments);
    return `${installments}x de ${valueInstallmentFormat} sem juros`;
  }

  getValueProductWithDiscount(product: Product): number {
    if (product !== undefined) {
      let value = product.value || 0;
      let discount = product.discount || 0;
      if (discount > 0) {
        value = value - (value * discount) / 100;
      }
      return value;
    } else {
      return 0;
    }
  }

  getValueWithDiscount(product: Product): number {
    const discount = product.discount || 0;
    let value = product.value || 0;
    if (discount > 0) {
      value = value - (value * discount) / 100;
    }
    return value;
  }

  getUrlProduct(product: Product) {
    let sub = product?.subCategory?.toLowerCase()?.replaceAll('_', '-');
    let model = product?.model?.toLowerCase()?.replaceAll('_', '-');
    let name = product?.name?.toLowerCase()?.replace(/ /g, '-');
    name = name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    name = name
      ?.replace(/% ?/g, '')
      ?.replaceAll('/', '')
      ?.replaceAll('(', '')
      ?.replaceAll(')', '')
      ?.replaceAll(',', '-')
      ?.replaceAll('.', '-')
      ?.replace(/\---/gi, '-')
      ?.replace(/\--/gi, '-');
    return `${sub}/${model}/${product.id}/${name}`;
  }

  getUrlSimpleProduct(product: Product) {
    let name = product?.name?.toLowerCase()?.replace(/ /g, '-');
    name = name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    name = name
      ?.replace(/% ?/g, '')
      ?.replaceAll('/', '')
      ?.replaceAll('(', '')
      ?.replaceAll(')', '')
      ?.replaceAll(',', '-')
      ?.replaceAll('.', '-')
      ?.replace(/\---/gi, '-')
      ?.replace(/\--/gi, '-');
    return `${product.id}/${name}`;
  }

  getTotalInstallments(value: number, free: boolean) {
    let installment =
      value / environment.minimumInstallment < 1
        ? 1
        : value / environment.minimumInstallment;
    if (free) {
      installment = installment > 4 ? 4 : installment;
    } else {
      installment = installment > 12 ? 12 : installment;
    }
    return Math.floor(installment);
  }
  getInstallments(value: any) {
    const installmentsValue = [];
    const installments = this.getTotalInstallments(value, false);

    for (let index = 1; index <= installments; index++) {
      if (index <= 4) {
        installmentsValue.push(value / index);
      } else {
        const interest: number = (this.getInterest(index) / 100) * value;
        const valueTotalWithInterest = value + interest;
        const amountWithInterest = valueTotalWithInterest / index;
        installmentsValue.push(amountWithInterest);
      }
    }
    return installmentsValue;
  }

  getInterest(installment: number): number {
    switch (installment) {
      case 5:
        return InterestPercentageEnum._5X;
      case 6:
        return InterestPercentageEnum._6X;
      case 7:
        return InterestPercentageEnum._7X;
      case 8:
        return InterestPercentageEnum._8X;
      case 9:
        return InterestPercentageEnum._9X;
      case 10:
        return InterestPercentageEnum._10X;
      case 11:
        return InterestPercentageEnum._11X;
      case 12:
        return InterestPercentageEnum._12X;
      default:
        return 1;
    }
  }

  public static getSessionByTitle(
    produtct: Product,
    title: string
  ): InformationSection {
    if (
      produtct.informationsSection &&
      produtct.informationsSection.length > 0
    ) {
      return (
        produtct.informationsSection
          .filter((session: InformationSection) => session.title === title)
          ?.slice()
          ?.shift() || new InformationSection()
      );
    } else {
      return new InformationSection();
    }
  }

  fromExcel?(
    categories: Array<InfoCategory>,
    origins: Array<AbstractInfoEnum>,
    line: number,
    productExcel: any[]
  ) {
    this.validationMessages = [];
    this.enabled = true;

    let category: InfoCategory = new InfoCategory();
    if (productExcel[1]) {
      category =
        categories.find(
          (item) =>
            item?.description?.toUpperCase() === productExcel[1]?.toUpperCase()
        ) || new InfoCategory();

      if (category) {
        this.category = category?.id;
      } else {
        this.validationMessages.push(
          `Linha: ${line} - O campo CATEGORIA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo CATEGORIA está em branco!`
      );
    }

    let subCategory: InfoSubCategory = new InfoSubCategory();
    if (productExcel[2]) {
      subCategory =
        category.subCategories?.find(
          (item) =>
            item?.description?.toUpperCase() === productExcel[2]?.toUpperCase()
        ) || new InfoSubCategory();

      if (subCategory) {
        this.subCategory = subCategory?.id;
      } else {
        this.validationMessages.push(
          `Linha: ${line} - O campo SUBCATEGORIA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo SUBCATEGORIA está em branco!`
      );
    }

    if (productExcel[0]) {
      this.name = productExcel[0];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo NOME está em branco!`
      );
    }

    if (productExcel[3]) {
      this.unitCommercialize = subCategory?.unitsCommercialize.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[3]?.toLowerCase()
      )?.id;

      if (!this.unitCommercialize) {
        this.validationMessages.push(
          `Linha: ${line} - O campo UNIDADE COMERCIALIZADORA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo UNIDADE COMERCIALIZADORA está em branco!`
      );
    }

    if (productExcel[4]) {
      this.model = subCategory?.models.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[4]?.toLowerCase()
      )?.id;
      if (!this.model) {
        this.validationMessages.push(
          `Linha: ${line} - O campo MODELO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo MODELO está em branco!`
      );
    }

    if (productExcel[5]) {
      this.value = productExcel[5]?.toFixed(2);
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo VALOR está em branco!`
      );
    }

    if (productExcel[6]) {
      this.stockBalance = productExcel[6];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ESTOQUE está em branco!`
      );
    }

    if (productExcel[7]) {
      this.canBeGeneratedWithStockZero =
        productExcel[7]?.toLowerCase() === 'sim' ? true : false;
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PODE VENDER SEM ESTOQUE está em branco!`
      );
    }

    if (productExcel[8]) {
      this.daysOnlyOrder = productExcel[8];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PRAZO DE ENCOMENDA está em branco!`
      );
    }

    if (productExcel[9]) {
      this.heightThickness = subCategory?.heightThickness.find(
        (item) => item.description === productExcel[9]
      )?.id;

      if (!this.heightThickness) {
        this.validationMessages.push(
          `Linha: ${line} - O campo ALTURA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ALTURA está em branco!`
      );
    }

    if (productExcel[10]) {
      this.width = subCategory?.widths.find(
        (item) => item.description === productExcel[10]
      )?.id;
      if (!this.width) {
        this.validationMessages.push(
          `Linha: ${line} - O campo LARGURA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo LARGURA está em branco!`
      );
    }

    if (productExcel[11]) {
      this.length = subCategory?.lengths.find(
        (item) => item.description === productExcel[11]
      )?.id;

      if (!this.length) {
        this.validationMessages.push(
          `Linha: ${line} - O campo COMPRIMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COMPRIMENTO está em branco!`
      );
    }

    if (productExcel[12]) {
      this.color = subCategory?.colors.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[12]?.toLowerCase()
      )?.id;

      if (!this.color) {
        this.validationMessages.push(
          `Linha: ${line} - O campo COR está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COR está em branco!`
      );
    }

    if (productExcel[13]) {
      this.finish = subCategory?.finishes.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[13]?.toLowerCase()
      )?.id;

      if (!this.finish) {
        this.validationMessages.push(
          `Linha: ${line} - O campo ACABAMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ACABAMENTO está em branco!`
      );
    }

    if (productExcel[14]) {
      this.weight = productExcel[14]?.toFixed(2);
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PESO está em branco!`
      );
    }

    if (productExcel[15]) {
      this.brand = subCategory?.brands.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[15]?.toLowerCase()
      )?.id;

      if (!this.brand) {
        this.validationMessages.push(
          `Linha: ${line} - O campo MARCA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo MARCA está em branco!`
      );
    }

    if (productExcel[16]) {
      this.fabricComposition = subCategory?.fabricCompositions.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[16]?.toLowerCase()
      )?.id;

      if (!this.fabricComposition) {
        this.validationMessages.push(
          `Linha: ${line} - O campo COMPOSIÇÃO DO TECIDO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COMPOSIÇÃO DO TECIDO está em branco!`
      );
    }

    if (productExcel[17]) {
      this.actuationType = subCategory?.actuationTypes.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[17]?.toLowerCase()
      )?.id;

      if (!this.actuationType) {
        this.validationMessages.push(
          `Linha: ${line} - O campo TIPO DE ACIONAMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo TIPO DE ACIONAMENTO está em branco!`
      );
    }

    if (productExcel[18]) {
      this.actuationSide = subCategory?.actuationSides.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[18]?.toLowerCase()
      )?.id;

      if (!this.actuationSide) {
        this.validationMessages.push(
          `Linha: ${line} - O campo LADO DE ACIONAMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo LADO DO ACIONAMENTO está em branco!`
      );
    }

    if (productExcel[19]) {
      this.packingWidth = productExcel[19];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo LARGURA DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[20]) {
      this.packingLength = productExcel[20];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COMPRIMENTO DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[21]) {
      this.packingHeightThickness = productExcel[21];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ALTURA DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[22]) {
      this.packingWeight = productExcel[22];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PESO DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[23]) {
      this.qtdMaxPiecesPerVolume = productExcel[23];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo MÁXIMO DE PEÇAS está em branco!`
      );
    }

    if (productExcel[24]) {
      this.origin = origins.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[24]?.toLowerCase()
      )?.id;

      if (!this.origin) {
        this.validationMessages.push(
          `Linha: ${line} - O campo ORIGEM está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ORIGEM está em branco!`
      );
    }

    if (productExcel[25]) {
      this.nbm = productExcel[25];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo NCM (NBM) está em branco!`
      );
    }

    if (productExcel[26]) {
      this.warrantyTime = productExcel[26];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo TEMPO DE GARANTIA está em branco!`
      );
    }

    if (productExcel[27] || productExcel[27]?.length < 250) {
      this.warrantyText = productExcel[27];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo TEXTO DA GARANTIA está em branco ou é maior que 250 caracteres!`
      );
    }

    if (productExcel[28]) {
      this.videoUrl = productExcel[28];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo URL DO VÍDEO está em branco!`
      );
    }

    //Section
    this.informationsSection = [];
    let newSc: InformationSection = new InformationSection();
    newSc.title = 'Sobre';
    newSc.orderDisplay = 1;
    newSc.description = productExcel[29];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Aplicações';
    newSc.orderDisplay = 2;
    newSc.description = productExcel[30];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Vantagens';
    newSc.orderDisplay = 3;
    newSc.description = productExcel[31];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Observações';
    newSc.orderDisplay = 4;
    newSc.description = productExcel[32];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Limpeza';
    newSc.orderDisplay = 5;
    newSc.description = productExcel[33];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Links Úteis';
    newSc.orderDisplay = 6;
    newSc.description = productExcel[34];
    this.informationsSection.push(newSc);

    //Images
    this.photos = [];
    let imgLink = new AttachmentProductLink();
    if (productExcel[35]) {
      imgLink.urlAPI = productExcel[35];
      imgLink.url = imgLink.urlAPI;
      imgLink.orderDisplay = 1;
      this.photos.push(imgLink);
    }
    if (productExcel[36]) {
      imgLink = new AttachmentProductLink();
      imgLink.urlAPI = productExcel[36];
      imgLink.url = imgLink.urlAPI;
      imgLink.orderDisplay = 2;
      this.photos.push(imgLink);
    }
    if (productExcel[37]) {
      imgLink = new AttachmentProductLink();
      imgLink.urlAPI = productExcel[37];
      imgLink.url = imgLink.urlAPI;
      imgLink.orderDisplay = 3;
      this.photos.push(imgLink);
    }
    if (productExcel[38]) {
      imgLink = new AttachmentProductLink();
      imgLink.urlAPI = productExcel[38];
      imgLink.url = imgLink.urlAPI;
      imgLink.orderDisplay = 4;
      this.photos.push(imgLink);
    }
    if (productExcel[39]) {
      imgLink = new AttachmentProductLink();
      imgLink.urlAPI = productExcel[39];
      imgLink.url = imgLink.urlAPI;
      imgLink.orderDisplay = 5;
      this.photos.push(imgLink);
    }
  }
}
