<ng-container *ngIf="loaderService.initialLoaded">
  <div class="products-details-main">
    <div class="container">
      <div class="products-details-main-content">
        <h2
          class="product-unavailable mobile-visible"
          *ngIf="!_product?.enabled"
        >
          PRODUTO INDISPONÍVEL NO MOMENTO!
        </h2>
        <div
          class="products-details-main-content-product"
          [ngClass]="{
            'products-main-unavailable': !_product?.enabled
          }"
        >
          <div class="products-galery">
            <div
              class="products-infos-square-meter-name"
              *ngIf="_product?.productPerSquareMeter"
            >
              <h2>{{ _product?.name }}</h2>
              <h3>
                Marca: <span>{{ _product?.brandDescription }}</span> | Peso:
                <span>{{ _product?.weight }} kg/m² </span>| SKU:
                <span>{{ _product?.id }}</span>
              </h3>
            </div>

            <app-galleria
              [images]="productsPhotos || []"
              [discount]="_product?.discount || 0"
            ></app-galleria>
          </div>
          <div class="products-infos" *ngIf="!_product?.productPerSquareMeter">
            <h2>{{ _product?.name }}</h2>

            <h3
              class="products-infos-interest-discount"
              *ngIf="productValuesDetails.discount && _product?.enabled"
            >
              de
              <span>{{ productValuesDetails.value | customCurrency }}</span> por
            </h3>
            <h3
              class="products-infos-interest-free-value"
              *ngIf="_product?.enabled"
            >
              {{ productValuesDetails.valueWithDiscount | customCurrency }}
              {{
                productValuesDetails.discount
                  ? " à vista no PIX"
                  : " em " + productValuesDetails.valueInterestFree
              }}
            </h3>
            <h3
              class="products-infos-cash-value"
              *ngIf="!productValuesDetails.discount && _product?.enabled"
            >
              {{
                productValuesDetails.valueWithCashDiscount | customCurrency
              }}
              à vista
            </h3>
            <h3
              class="products-infos-installments-value"
              *ngIf="productValuesDetails.discount && _product?.enabled"
            >
              <span
                >{{ productValuesDetails.value | customCurrency }} em
                {{ productValuesDetails.valueInstallmentWithoutJuros }}</span
              >
            </h3>
            <h3
              class="products-infos-installments-value"
              *ngIf="_product?.enabled"
            >
              ou em até <span>{{ productValuesDetails.valueInstallment }}</span>
            </h3>
            <h3 class="products-infos-description">
              {{ _product?.description }} -
              <span (click)="onClickMoreInf()">Ver mais informações!</span>
            </h3>

            <h2
              class="product-unavailable mobile-invisible"
              *ngIf="!_product?.enabled"
            >
              PRODUTO INDISPONÍVEL NO MOMENTO!
            </h2>

            <div class="products-infos-zipcode" *ngIf="_product?.enabled">
              <div class="products-infos-zipcode-header">
                <p>Quantidade</p>
                <p>Seu CEP</p>
              </div>
              <div class="products-infos-zipcode-content">
                <input
                  class="products-infos-zipcode-quantity"
                  pInputText
                  type="number"
                  [value]="quantitySelected"
                  (change)="setQuantitySelected($event)"
                  name="quantitySelected"
                  aria-label="Alterar quantidade do produto"
                  [min]="0"
                  [max]="100"
                />

                <app-zip-code-input
                  placeholder="Seu CEP"
                  (changed)="onEvtChangeZipCode()"
                  (zipcodeError)="onEvtZipCodeError()"
                  (addressFound)="addressFound($event)"
                  [showKnowZipCoded]="false"
                  [emitterEventError]="true"
                ></app-zip-code-input>

                <p-progressSpinner
                  strokeWidth="3"
                  *ngIf="loadingFreight"
                  aria-label="Buscando informações de frete"
                ></p-progressSpinner>

                <div
                  class="products-infos-zipcode-content-quote"
                  *ngIf="quotes?.carriers?.length || 0 > 0"
                >
                  <p>
                    <span>{{ getCarrier(1).displayName?.toLowerCase() }}</span>
                    -
                    {{ getCarrier(1).deliveryTime }}
                    {{
                      getCarrier(1).deliveryTime === 1
                        ? "dia" + " útil"
                        : "dias úteis"
                    }}
                    - {{ getCarrier(1).priceFreight | customCurrency }}
                  </p>
                  <p>
                    ou retire na loja em {{ getCarrier(0).deliveryTime }}
                    {{
                      getCarrier(0).deliveryTime === 1
                        ? "dia" + " útil"
                        : "dias úteis"
                    }}
                    sem custos!
                  </p>
                </div>
              </div>
            </div>

            <div
              class="products-infos-quote-error-freight"
              *ngIf="errorFreight"
            >
              <p>{{ errorFreight }}</p>
            </div>
            <div
              class="products-infos-cart mobile-invisible"
              *ngIf="_product?.enabled"
            >
              <p-button
                class="prime-button-rm prime-button-whats"
                label="Compre pelo WhatsApp"
                icon="pi pi-whatsapp"
                iconPos="left"
                aria-label="Compre pelo WhatsApp"
                (click)="
                  onClickBuyByWhatss();
                  $event.stopPropagation();
                  $event.preventDefault()
                "
              ></p-button>
              <p-button
                class="prime-button-rm"
                label="ADICIONAR AO CARRINHO"
                icon="pi pi-shopping-cart"
                iconPos="right"
                aria-label="Adicionar produto ao carrinho"
                (click)="onClickAddToCart()"
                [disabled]="errorFreight !== ''"
              ></p-button>
            </div>
            <div
              class="products-infos-other-options"
              *ngIf="!_product?.isGrouped() && _product?.productsColorBalance"
            >
              <p>Outras Opções:</p>
              <div class="products-infos-other-options-content">
                <a
                  href="{{ item.url }}"
                  *ngFor="let item of _product?.productsColorBalance"
                  aria-label="Veja outras opções"
                >
                  <img
                    src="{{ item?.urlFirstImage }}"
                    [alt]="item?.name"
                    width="80"
                    height="80"
                /></a>
              </div>
            </div>
          </div>

          <div class="products-infos" *ngIf="_product?.productPerSquareMeter">
            <div
              class="products-square-meter"
              *ngIf="_product?.productPerSquareMeter && _product?.enabled"
            >
              <div class="products-square-meter-title">
                <h3 *ngIf="_product?.isPersiana()">Monte sua Persiana</h3>
                <h3 *ngIf="!_product?.isPersiana()">Monte seu Toldo</h3>
                <div>
                  <a
                    href="https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf"
                    target="_blank"
                    >MANUAL DE INSTALAÇÃO</a
                  >
                  <a (click)="isFullScreen = true">COMO MEDIR</a>
                  <img
                    *ngIf="isFullScreen"
                    src="assets/images/products/como-medir.jpg"
                    class="full-screen-image"
                    (click)="isFullScreen = false"
                  />
                </div>
              </div>

              <form>
                <div
                  class="products-square-meter-form item-dropdown"
                  *ngIf="_product?.productPerSquareMeter"
                >
                  <div class="products-square-meter-item">
                    <h4>1º - Selecione a largura:</h4>
                    <div>
                      <p-dropdown
                        [options]="optionsWidth"
                        [(ngModel)]="optionsWidthSelected"
                        (onChange)="onChangeOptionsWidth($event)"
                        name="optionsWidthSelected"
                        optionLabel="name"
                        placeholder="Largura:"
                        [filter]="true"
                        filterBy="name"
                      ></p-dropdown>
                      <img
                        class="img-witdh"
                        width="50"
                        height="50"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/1.webp"
                        alt="Escolha a Largura da persiana"
                      />
                    </div>
                  </div>
                  <div class="products-square-meter-item">
                    <h4>
                      2º - Selecione
                      {{ _product?.isPersiana() ? "a Altura:" : "o Avanço" }}
                    </h4>
                    <div>
                      <p-dropdown
                        [options]="optionsHeight"
                        [(ngModel)]="optionsHeightSelected"
                        (onChange)="onChangeOptionsHeight($event)"
                        name="optionsHeightSelected"
                        optionLabel="name"
                        placeholder="{{
                          _product?.isPersiana() ? 'Altura:' : 'Avanço'
                        }}"
                        [filter]="true"
                        filterBy="name"
                      ></p-dropdown>
                      <img
                        class="img-heigh"
                        width="50"
                        height="50"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/2.webp"
                        alt="{{
                          _product?.isPersiana()
                            ? 'Escolha a altura:'
                            : 'Escolha o avanço'
                        }}"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="products-square-meter-form"
                  *ngIf="_product?.isPersiana()"
                >
                  <div class="products-square-meter-item">
                    <h4>3º - Selecione o lado do Comando:</h4>
                    <div
                      class="products-square-meter-itens"
                      [ngClass]="{
                        'error-options':
                          validPersianas &&
                          !optionsBlinds.controlSideLeft &&
                          !optionsBlinds.controlSideRight
                      }"
                    >
                      <div
                        class="products-square-meter-item"
                        [ngClass]="{
                          'products-square-meter-item-selected':
                            optionsBlinds.controlSideLeft
                        }"
                        (click)="
                          optionsBlinds.controlSideLeft =
                            !optionsBlinds.controlSideLeft;
                          optionsBlinds.controlSideRight = false
                        "
                      >
                        <img
                          width="50"
                          height="50"
                          src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/3.webp"
                          alt="Selecione o lado esquerdo"
                        />
                        <p>Esquerdo</p>
                      </div>

                      <div
                        class="products-square-meter-item"
                        [ngClass]="{
                          'products-square-meter-item-selected':
                            optionsBlinds.controlSideRight
                        }"
                        (click)="
                          optionsBlinds.controlSideRight =
                            !optionsBlinds.controlSideRight;
                          optionsBlinds.controlSideLeft = false
                        "
                      >
                        <img
                          width="50"
                          height="50"
                          src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/4.webp"
                          alt="Selecione o lado direito"
                        />
                        <p>Direito</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="products-square-meter-form"
                  *ngIf="_product?.isPersiana()"
                >
                  <div class="products-square-meter-item">
                    <h4>4º - Selecione o acionamento:</h4>
                    <div
                      class="products-square-meter-itens"
                      [ngClass]="{
                        'error-options':
                          validPersianas &&
                          !optionsBlinds.driveManual &&
                          !optionsBlinds.driveMotor
                      }"
                    >
                      <div
                        class="products-square-meter-item"
                        [ngClass]="{
                          'products-square-meter-item-selected':
                            optionsBlinds.driveManual
                        }"
                        (click)="onClickDrive(true, false)"
                      >
                        <img
                          width="50"
                          height="50"
                          src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/5.webp"
                          alt="Selecione o acionamento manual"
                        />
                        <p>Manual</p>
                      </div>
                      <div
                        class="products-square-meter-item"
                        [ngClass]="{
                          'products-square-meter-item-selected':
                            optionsBlinds.driveMotor
                        }"
                        (click)="onClickDrive(false, true)"
                      >
                        <img
                          width="50"
                          height="50"
                          src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/6.webp"
                          alt="Selecione o acionamento motorizado"
                        />
                        <p>Motor + {{ valueMotor | customCurrency }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="products-square-meter-form"
                  *ngIf="_product?.isPersiana()"
                >
                  <div
                    class="products-square-meter-item"
                    *ngIf="!_product?.isPersianaRomana()"
                  >
                    <h4>5º - Selecione o acabamento:</h4>
                    <div
                      class="products-square-meter-itens"
                      [ngClass]="{
                        'error-options':
                          validPersianas &&
                          !optionsBlinds.withoutPanel &&
                          !optionsBlinds.withPanel
                      }"
                    >
                      <div
                        class="products-square-meter-item"
                        [ngClass]="{
                          'products-square-meter-item-selected':
                            optionsBlinds.withoutPanel
                        }"
                        (click)="onClickPanel(true, false)"
                      >
                        <img
                          width="50"
                          height="50"
                          src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/7.webp"
                          alt="Selecione o acabamento sem bandô"
                        />
                        <p>Sem Bandô</p>
                      </div>
                      <div
                        class="products-square-meter-item"
                        [ngClass]="{
                          'products-square-meter-item-selected':
                            optionsBlinds.withPanel
                        }"
                        (click)="onClickPanel(false, true)"
                      >
                        <img
                          width="50"
                          height="50"
                          src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/9.webp"
                          alt="Selecione o acabamento com bandô"
                        />
                        <p>
                          Bandô +{{ getValueTotalBando() | customCurrency }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div class="products-square-meter-values">
                <div class="products-square-meter-values-infos">
                  <h2>Valor Total do Produto</h2>
                  <h3
                    class="products-infos-interest-discount"
                    *ngIf="productValuesDetails.discount && _product?.enabled"
                  >
                    de
                    <span>{{
                      productValuesDetails.value | customCurrency
                    }}</span>
                    por
                  </h3>
                  <h3
                    class="products-infos-interest-free-value"
                    *ngIf="_product?.enabled"
                  >
                    {{
                      productValuesDetails.valueWithDiscount | customCurrency
                    }}
                    {{
                      productValuesDetails.discount
                        ? " à vista no PIX"
                        : " em " + productValuesDetails.valueInterestFree
                    }}
                  </h3>
                  <h3
                    class="products-infos-cash-value"
                    *ngIf="!productValuesDetails.discount && _product?.enabled"
                  >
                    {{
                      productValuesDetails.valueWithCashDiscount
                        | customCurrency
                    }}
                    à vista
                  </h3>
                  <h3
                    class="products-infos-installments-value"
                    *ngIf="productValuesDetails.discount && _product?.enabled"
                  >
                    <span
                      >{{ productValuesDetails.value | customCurrency }} em
                      {{
                        productValuesDetails.valueInstallmentWithoutJuros
                      }}</span
                    >
                  </h3>
                  <h3
                    class="products-infos-installments-value"
                    *ngIf="_product?.enabled"
                  >
                    ou em até
                    <span>{{ productValuesDetails.valueInstallment }}</span>
                  </h3>
                </div>
                <div class="products-square-meter-values-cart">
                  <p-button
                    class="prime-button-rm"
                    label="ADICIONAR AO CARRINHO"
                    icon="pi pi-shopping-cart"
                    iconPos="right"
                    aria-label="Adicionar produto ao carrinho"
                    (click)="onClickAddToCart()"
                  ></p-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="products-details-main-content-sections"
          [ngClass]="{
            'products-main-unavailable': !_product?.enabled
          }"
        >
          <app-products-shop-sessions
            [sessions]="_product?.informationsSection || []"
          ></app-products-shop-sessions>
        </div>
        <div class="products-details-main-content-more">
          <div class="products-more-title">Veja também</div>
          <div class="products-more-content">
            <app-carousel-products
              [products]="productsMore"
            ></app-carousel-products>
          </div>
        </div>

        <div
          class="products-details-main-content-cart-mobile mobile-visible"
          *ngIf="_product?.enabled"
        >
          <p-button
            class="prime-button-rm"
            label="ADICIONAR AO CARRINHO"
            icon="pi pi-shopping-cart"
            iconPos="right"
            aria-label="Adicionar produto ao carrinho"
            (click)="onClickAddToCart()"
            [disabled]="errorFreight !== ''"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
  <app-faq [subCategory]="subCategorySelected"></app-faq>
  <app-products-shop-similar
    [similarProduct]="similarProduct"
    (onClickbtnClose)="showSimilarProduct = false"
    *ngIf="showSimilarProduct"
  ></app-products-shop-similar>
</ng-container>
