import { Injectable } from '@angular/core';
import { ContactData } from '../../shared/components/contact-form/contact-form.component';
import TableOptions from '../../shared/models/contact/table-options';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  public static readonly TYPE_CONTACT = 'contact';
  public static readonly TYPE_DEALER = 'dealer';
  public static readonly TYPE_SAC = 'sac';

  public static readonly TABLE_ID_SAC = 4187457;
  public static readonly TABLE_ID_FORM = 4241590;

  public OPTIONS_SAC: TableOptions[] = [
    {
      id: 408090296,
      name: 'Abertura de Garantia',
      tableId: ContactService.TABLE_ID_SAC,
    },
    {
      id: 408090297,
      name: 'Devolução Por Arrependimento - 7 dias',
      tableId: ContactService.TABLE_ID_SAC,
    },
    {
      id: 410807579,
      name: 'Itens diferentes do pedido',
      tableId: ContactService.TABLE_ID_SAC,
    },
    {
      id: 409270643,
      name: 'Outras Duvidas',
      tableId: ContactService.TABLE_ID_SAC,
    },
    {
      id: 409157233,
      name: 'Troca de cor ou espessura',
      tableId: ContactService.TABLE_ID_SAC,
    },
  ];

  public OPTIONS_FORM: TableOptions[] = [
    {
      id: 409270722,
      name: 'Persianas Sob medida',
      tableId: ContactService.TABLE_ID_FORM,
    },
    { id: 409270719, name: 'Outros', tableId: ContactService.TABLE_ID_FORM },
  ];

  constructor(private httpService: HttpService) {}

  public send(
    contactData: ContactData,
    contactType: string | undefined
  ): Promise<boolean> {
    const endPoint =
      contactType === ContactService.TYPE_SAC
        ? 'contact/sac/'
        : contactType === ContactService.TYPE_CONTACT
        ? 'contact/sale/'
        : 'contact/dealer/';
    return new Promise((resolve, reject) => {
      this.httpService.post(endPoint, contactData).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao enviar a sua mensagem!';
          reject(msg);
        },
      });
    });
  }

  public async getOptionsTablesOptions(
    tableId: number
  ): Promise<Array<TableOptions>> {
    return tableId === ContactService.TABLE_ID_SAC
      ? this.OPTIONS_SAC
      : this.OPTIONS_FORM;
  }

  public async getOptionsTablesOptionsPloomes(
    tableId: number
  ): Promise<Array<TableOptions>> {
    const options: Array<TableOptions> = [];
    return new Promise((resolve, reject) => {
      this.httpService.get(`contact/table-options/${tableId}`).subscribe({
        next: (res: any) => {
          if (res) {
            res?.forEach((option: any) => {
              options.push(Object.assign(new TableOptions(), option));
            });
          }
          resolve(options);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar os dados da aplicação.';
          reject(msg);
        },
      });
    });
  }
}
