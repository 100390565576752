import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PrimeNGModule } from '../../primeng.module';
import { AuthService } from '../../services/auth/auth.service';
import { BudgetService } from '../../services/budget/budget.service';
import { CartService } from '../../services/cart/cart.service';
import { FreightService } from '../../services/freight/freight.service';
import { GoogleAnalyticsService } from '../../services/google/google-analytics.service';
import { LoaderService } from '../../services/loader/loader.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../services/product/products.service';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { CarouselProductsComponent } from '../../shared/components/carousel-products/carousel-products.component';
import { FreightQuotesComponent } from '../../shared/components/freight-quotes/freight-quotes.component';
import { HasNoItensComponent } from '../../shared/components/has-no-itens/has-no-itens.component';
import { ZipCodeInputComponent } from '../../shared/components/zip-code-input/zip-code-input.component';
import Budget from '../../shared/models/budget/budget';
import { Cart, CartItem } from '../../shared/models/cart/cart';
import CartCheckoutDetails from '../../shared/models/cart/cart-checkout-details';
import CalculateShipping from '../../shared/models/freight/calculate-shipping';
import Freight from '../../shared/models/freight/freight';
import FreightSelected from '../../shared/models/freight/freight-selected';
import ItemShipping from '../../shared/models/freight/item-shipping';
import QuoteList from '../../shared/models/freight/quote-list';
import { PageResponse } from '../../shared/models/page/page-response';
import { Product } from '../../shared/models/product/product';
import ProductFilter from '../../shared/models/product/product-filter';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import Address from '../../shared/models/user/address';
import User from '../../shared/models/user/user';
import { SharedModule } from '../../shared/shared.module';
import {
  validEmail,
  validName,
  validPhone,
} from '../../shared/utils/validators';
import { TWO_SECONDS_IN_MS, wait } from '../../shared/utils/wait';

export class BudgetError {
  public errorEmail?: boolean;
  public errorName?: boolean;
  public errorPhone?: boolean;
}

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    BreadcrumbComponent,
    PrimeNGModule,
    CarouselProductsComponent,
    HasNoItensComponent,
    ZipCodeInputComponent,
    FreightQuotesComponent,
    SharedModule,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  providers: [ToastService, MessageService],
})
export class CartComponent {
  public budget: Budget = new Budget();
  public budgetError: BudgetError = new BudgetError();
  public cart: Cart = {};
  public cartCheckout?: CartCheckoutDetails;
  public freightSelected: FreightSelected | undefined;
  public freightError: string | undefined;
  public idCart?: string | null;
  public loadingCart = true;
  public loadingFreight = false;
  public productsMore: Product[] = [];
  public quotes: QuoteList | undefined;
  public showBudget = false;
  public user: User | undefined = new User();
  public zipCode?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private budgetService: BudgetService,
    private cartService: CartService,
    private confirmationService: ConfirmationService,
    private freightService: FreightService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService,
    private metaServices: MetaTagsService,
    private productService: ProductsService,
    public router: Router,
    public sessionStorageService: SessionStorageService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  async ngOnInit() {
    this.loadingCart = true;
    await this.productService.getValueMotorBando();
    this.idCart = this.activatedRoute.snapshot.paramMap.get('idCart');
    this.zipCode = await this.localStorageService.get(
      StorageConstants.AMBIENTA_ZIP_CODE
    );
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CART,
        url[0]?.path,
        '',
        '',
        null
      );
    });

    this.configCart();
  }

  async configCart() {
    try {
      const cartItems = await this.cartService.getItems(this.idCart);
      this.cart.items = cartItems.map((c) => ({ ...c }));
      this.cartService.setAuxVariables(this.cart, this.freightSelected);
      if (this.user) {
        const cart = await this.cartService.get(false);
        if (cart && cart.id > 0) {
          this.cart.id = cart.id;
        }
      }
      this.getProductMore();
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingCart = false;
      this.googleAnalyticsService.sendEventViewCart(this.cart);
    }
  }

  getValueItem(cartProduct: CartItem, whithDiscount: boolean) {
    return this.cartService.getValueItem(cartProduct, whithDiscount);
  }

  async getProductMore() {
    try {
      const filter: ProductFilter = new ProductFilter();
      filter.inactive = false;
      filter.ignoreMainVariation = true;
      filter.withDiscountOrFeatured = true;

      filter.perPage = 40;
      const pageRes: PageResponse | undefined =
        await this.productService.products(filter);
      if ((pageRes?.totalElements || 0) > 0) {
        this.productsMore = pageRes?.content || [];
      }
    } catch (error) {
      console.log(error);
    }
  }

  async calculateFreight(zipCode: string) {
    if (!zipCode) {
      return;
    }
    this.freightError = undefined;
    this.loadingFreight = true;
    this.freightSelected = undefined;
    try {
      const calculate: CalculateShipping = new CalculateShipping();
      calculate.cep = zipCode;
      if ((this.cart?.id || 0) > 0) {
        calculate.cartId = this.cart.id;
      } else {
        calculate.itens = this.cart?.items?.map((i: CartItem) => {
          return new ItemShipping(i);
        });
      }

      if (calculate?.cartId || (calculate?.itens?.length || 0) > 0) {
        const quotes: QuoteList =
          await this.freightService.calculateShippingOrCart(calculate);
        if (quotes && (quotes?.carriers?.length || 0) > 0) {
          let daysOnlyOrder = 0;
          this?.cart?.items?.forEach((i: CartItem) => {
            if ((i?.product?.daysOnlyOrder || 0) > daysOnlyOrder) {
              daysOnlyOrder = i?.product?.daysOnlyOrder || 0;
            }
          });

          if (daysOnlyOrder > 0) {
            quotes?.carriers?.forEach((carrier) => {
              if (carrier.optionFreight === Freight.STORE_PICKUP) {
                carrier.deliveryTime =
                  (carrier.deliveryTime || 0) + daysOnlyOrder;
              }
            });
          }
          this.quotes = quotes;
        } else {
          const similarItems = this.cart?.items?.filter(
            (item: CartItem) => item?.product?.similarProduct
          );

          if (similarItems && similarItems?.length > 0) {
            this.confirmationService.confirm({
              message:
                'Há produto(s) sem entrega para sua região, porém temos ele em uma medida menor (cortado para envio). Deseja trocar para esse(s) produto(s)?',
              header: 'Confirmação',
              accept: async () => {
                similarItems.forEach(async (item) => {
                  await this.changeSimilarItem(item);
                });
              },
              reject: () => {
                this.setErrorFreight();
              },
            });
          } else {
            this.setErrorFreight();
          }
        }
      }
    } catch (err) {
      this.toastService.error('Ocorreu um erro ao consultar seu frete!');
    } finally {
      this.loadingFreight = false;
    }
  }

  async changeSimilarItem(similarItem: CartItem) {
    try {
      const product = await this.productService.get(
        similarItem?.product?.similarProduct?.toString() || ''
      );

      if (product) {
        similarItem.amount = 0;
        await this.cartService.addItem(similarItem, false);
        this.cart?.items?.splice(this.cart.items.indexOf(similarItem), 1);

        const cartItem: CartItem = {
          productId: product.id,
          amount: 1,
          product,
        };
        await this.cartService.addItem(cartItem, true);
        this.cart?.items?.push(cartItem);
      }
    } catch (error) {
      this.toastService.error(
        'Ocorreu um erro ao tentar trocar o produto: ' +
          similarItem?.product?.id
      );
    }
  }

  setErrorFreight() {
    const msg = 'Para cotar esse frete com 6 metros, solicite via whatsapp!';
    this.toastService.error(msg);
    this.freightError = msg;
  }

  hasCart(): boolean {
    return (this?.cart?.items?.length || 0) > 0 || false;
  }

  async onClickCheckout() {
    if (this.freightSelected) {
      await this.sessionStorageService.set(
        StorageConstants.AMBIENTA_REQUEST_BUDGET,
        true
      );
      await this.localStorageService.set(
        StorageConstants.AMBIENTA_FREIGHT,
        this.freightSelected
      );
      if (this.user) {
        this.userService.goToPayment();
      } else {
        this.userService.goToLogin();
      }
    } else {
      this.toastService.error('Selecione um frete para gerar seu pedido!');
    }
  }

  async changeZipCode() {
    this.quotes = undefined;
    this.freightError = '';
  }

  async addressFound(address: Address) {
    if (address?.zipCode) {
      this.zipCode = address?.zipCode || '';
      await this.calculateFreight(address?.zipCode);
    } else {
      this.zipCode = '';
      this.toastService.error('CEP não localizado!1');
    }
  }

  async setFreightSelected(freightSelected: FreightSelected) {
    this.freightSelected = freightSelected;
    this.cartService.setAuxVariables(this.cart, this.freightSelected);
  }

  removeItem(item: CartItem) {
    this.confirmationService.confirm({
      message: 'Deseja realmente remover este item?',
      header: 'Confirmação',
      accept: async () => {
        this.loaderService.showLoader();
        setTimeout(async () => {
          try {
            this.cart?.items?.splice(this.cart.items.indexOf(item), 1);
            this.cart.items = [...(this.cart.items || [])];
            item.amount = 0;
            await this.cartService.addItem(item, false);
            this.loaderService.hideLoader();
            if (this.cart?.items?.length > 0) {
              this.quotes = undefined;
              this.freightError = undefined;
              this.calculateFreight(this.zipCode || '');
            }
            this.cartService.setAuxVariables(this.cart, this.freightSelected);
          } catch (err) {
            this.loaderService.hideLoader();
            this.toastService.error('Ocorreu um erro ao remover o produto!');
          }
        }, 500);
      },
    });
  }

  async setQuantity(item: CartItem) {
    this.loaderService.showLoader();
    setTimeout(async () => {
      const quantity = item.amount || 0;
      const stockBalance = item?.product?.stockBalance || 0;
      try {
        if (
          stockBalance < quantity &&
          !item?.product?.canBeGeneratedWithStockZero
        ) {
          this.loaderService.hideLoader();
          const msg =
            stockBalance === 1
              ? `Existe apenas ${stockBalance} item disponível!`
              : `Existem apenas ${stockBalance} itens disponíveis!`;
          item.amount = item.amountOld;
          return this.toastService.error(
            `Quantidade informada não disponível. ${msg}`
          );
        }
        if (quantity < 1) {
          this.loaderService.hideLoader();
          this.removeItem(item);
        } else {
          item.amount = quantity;
          item.amountOld = quantity;
          const itemCart =
            this.cart?.items?.find((i) => i.productId === item.productId) || {};
          itemCart.amount = quantity;
          itemCart.amountOld = quantity;

          await this.cartService.addItem(item, false);
          this.loaderService.hideLoader();
          this.toastService.success('Quantidade alterada com sucesso!');
          await this.calculateFreight(this.zipCode || '');
          this.cartService.setAuxVariables(this.cart, this.freightSelected);
        }
      } catch (err) {
        this.loaderService.hideLoader();
        this.toastService.error('Erro ao adicionar quantidade!');
      }
    }, 500);
  }

  onClickDelete() {
    this.confirmationService.confirm({
      message: 'Deseja realmente excluir esse carrinho?',
      header: 'Confirmação',
      accept: async () => {
        this.deleteCart();
      },
    });
  }

  async deleteCart() {
    this.loaderService.showLoader();
    try {
      await this.cartService.delete(this.cart?.id || 0);
      this.loaderService.hideLoader();
      this.toastService.success('Carrinho excluído com sucesso!');
      this.cart = {};
    } catch (err) {
      this.toastService.error('Erro ao excluir o carrinho!');
      throw err;
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async onClickBtCreateBudget() {
    if (!this.zipCode) {
      this.toastService.error('Digite um CEP válido para obter sua cotação!');
      return;
    }

    if (!this.validBudget()) {
      this.toastService.error('Preencha todos os campos!');
      return;
    }
    this.loaderService.showLoader();
    try {
      if (this.budget) {
        this.budget.zipCode = this.zipCode;
        this.budget = await this.budgetService.save(this.budget, this.cart);
        this.loaderService.hideLoader();
        this.toastService.success('Orçamento criado com sucesso!');
        await wait(TWO_SECONDS_IN_MS);
        window.open(`${environment.linkCart}/${this.budget?.hash}`, '_blank');
        this.budget = new Budget();
      }
    } catch (error: any) {
      this.toastService.error(error?.message || 'Erro ao criar orçamento!');
      this.loaderService.hideLoader();
    }
  }

  validBudget() {
    this.budgetError.errorName = !validName(this.budget?.name || '');
    this.budgetError.errorEmail = !validEmail(this.budget?.email || '');
    this.budgetError.errorPhone = !validPhone(this.budget?.phone || '');

    const hasError =
      this.budgetError.errorName ||
      this.budgetError.errorEmail ||
      this.budgetError.errorPhone;

    return !hasError;
  }
}
