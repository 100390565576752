import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-banner-moviment',
  standalone: true,
  imports: [NgFor, RouterModule],
  templateUrl: './banner-moviment.component.html',
  styleUrl: './banner-moviment.component.scss',
})
export class BannerMovimentComponent {}
