import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { UserService } from '../../../services/user/user.service';
import { StorageConstants } from '../../../shared/models/storage/storage-constants';

@Injectable({
  providedIn: 'root',
})
class AuthGuardService {
  constructor(
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {}

  async canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.localStorageService
        .get(StorageConstants.AMBIENTA_TOKEN)
        .then((res) => {
          if (res) {
            resolve(true);
          } else {
            this.userService.goToLogin();
            resolve(false);
          }
        })
        .catch((_err) => {
          this.userService.goToLogin();
          resolve(false);
        });
    });
  }
}

export const authGuard: CanActivateFn = (route, state) => {
  return inject(AuthGuardService).canActivate();
};
