<app-breadcrumb
  [path]="'Termos e Condições Gerais de Uso'"
  [showTitle]="true"
></app-breadcrumb>
<div class="terms-main">
  <div class="container">
    <div class="terms-main-content">
      <details>
        <summary>TERMOS E CONDIÇÕES GERAIS DE USO</summary>
        <div>
          <p>
            Seja bem vindo ao Site da Ambientá Brasil, aqui você encontrará as
            regras e diretrizes que regulam o uso da sua relação com a nossa
            empresa. Aqui nós detalhamos em que hipóteses e para quais
            finalidades utilizamos suas informações quando você interage com o
            nosso site.
          </p>
          <p>
            Estes Termos e Condições não são aplicáveis a quaisquer terceiros,
            inclusive mídias sociais como Facebook, Instagram e YouTube, e
            afins. Ainda que você utilize tais mídias para se conectar conosco,
            você deve sempre se atentar aos termos e políticas aplicáveis a
            quaisquer sites ou plataformas de terceiros que você visitar.
          </p>
          <p>
            Caso você discorde de qualquer condição ou disposição do presente
            termo, ou no conteúdo do site, não acesse ou utilize-o, pois seu uso
            resulta na declaração de que você aceita os Termos e Condições de
            Uso e o Aviso de Privacidade adotada pelo responsável deste website.
          </p>
        </div>
      </details>

      <details>
        <summary>1. OBJETO</summary>
        <div>
          <p>
            1.1 O Site da Ambientá Brasil tem por objetivo apresentar para você,
            produtos do setor de soluções acrílicas e derivados, bem como
            serviços, através de plataforma de e-commerce, seguindo as
            legislações aplicáveis.
          </p>
          <p>
            1.2 Ao acessar nosso site você terá acesso a diversos produtos
            fabricados por nossos parceiros, e comercializados por nossa
            curadoria.
          </p>
        </div>
      </details>

      <details>
        <summary>2. SERVIÇOS DISPONÍVEIS NO SITE</summary>
        <div>
          <p>2.1 São serviços oferecidos aos nossos clientes:</p>
          <p>
            2.1.1 Se cadastrar para receber novidades da Ambientá Brasil e para
            realizar compras de produtos do setor de soluções acrílicas e
            derivados.
          </p>
          <p>
            2.1.2 Formas de entrar em contato com a empresa para receber ofertas
            e cotações especiais de frete.
          </p>
          <p>2.1 3 Ter acesso à nossa política de trocas e devoluções</p>
          <p>
            2.1.4 Acesso a canal para solicitar trocas e devoluções de produtos.
          </p>
        </div>
      </details>

      <details>
        <summary>3. CADASTRO NO SITE</summary>
        <div>
          <p>
            3.1 Você deve ser uma pessoa física, maior de 18 (dezoito) anos e
            deve manifestar seu aceite com os termos do presente documento para
            acessar e usar o Site da Ambientá Brasil;
          </p>
          <p>
            3.2 Fica proibido o uso da plataforma por menores de idade, e caso
            seja identificado a conta será excluída.
          </p>
          <p>
            3.3 O cadastro inicial deve conter os seguintes dados pessoais: nome
            completo, CPF ou CNPJ e Inscrição Estadual, telefone para contato,
            data de nascimento, e-mail válido, senha de acesso, endereço
            completo para entrega;
          </p>
          <p>
            3.4 Cada usuário poderá ter uma única conta vinculada ao seu CPF ou
            CNPJ;
          </p>
          <p>
            3.5 É de sua responsabilidade a veracidade dos seus dados
            informados, bem como, sua alteração e atualização, devendo os dados
            serem legítimos e exatos, para a correta comunicação entre a RM
            Policarbonatos e você.
          </p>
          <p>
            3.6 Os cadastros realizados na plataforma poderão ser recusados pela
            Ambientá Brasil, que fornecerá esclarecimentos sobre a recusa
            mediante solicitação.
          </p>
        </div>
      </details>

      <details>
        <summary>4. SENHAS</summary>
        <div>
          <p>
            4.1 Para acessar a sua conta, você deverá criar uma senha de acesso,
            e nós recomendamos que você escolha uma senha segura, com letras
            maiúsculas, minúsculas, números e caracteres especiais para garantir
            sua segurança.
          </p>
          <p>
            4.2 A sua senha de acesso é completamente pessoal e intransferível,
            e você deverá ser o responsável pela sua guarda. Caso você se
            esqueça da sua senha, você poderá solicitar a redefinição de senha
            através do link "Esqueci minha senha" e receber um e-mail com as
            instruções.
          </p>
        </div>
      </details>

      <details>
        <summary>5. PAGAMENTOS</summary>
        <div>
          <p>
            5.1 Os pagamentos das compras realizadas por você, poderá ser
            através de empresas terceirizadas, utilizando sistemas de "check in"
            seguro e outros.
          </p>
          <p>
            5.2 A Ambientá Brasil reunirá esforços para garantir que os serviços
            de pagamentos prestados pela empresa responsável pela gestão de
            pagamentos funcionará livre de erros, interrupções,
            mal-funcionamentos, atrasos ou outras imperfeições.
          </p>
          <p>
            5.3 Para compras consideradas de alto valor e/ou volume, a RM
            Policarbonatos, demanda o período de até 48 horas úteis para o
            processamento da compra. Após tal período, o cronograma de compras
            seguirá o fluxo normal de compras e você será informado de cada um
            dos passos do referido fluxo.
          </p>
        </div>
      </details>

      <details>
        <summary>6. RESPONSABILIDADES</summary>
        <div>
          <p>
            6.1 A Ambientá Brasil não é a fabricante dos produtos e/ou serviços
            ofertados, guarda apenas a posse dos mesmos, facilitando a entrega
            e/ou uso dos produtos e/ou serviços cuja aquisição ocorra através da
            nossa plataforma.
          </p>
          <p>
            6.2 A Ambientá Brasil recomenda que toda transação seja realizada
            com cautela e bom senso, e você deverá sopesar os riscos da
            aquisição dos produtos e serviços.
          </p>
        </div>
      </details>

      <details>
        <summary>7. CANCELAMENTO E EXCLUSÃO</summary>
        <div>
          <p>
            7.1 Você pode solicitar o cancelamento e exclusão da sua conta de
            acesso, através de solicitação que deverá ser encaminhada para o
            e-mail privacidade&#64;ambientabrasil.com.br
          </p>
          <p>
            7.2 Na hipótese de cancelamento e exclusão da conta de acesso, o
            usuário perderá automaticamente o acesso a todos os recursos
            oferecidos pela Ambientá Brasil até a realização de um novo
            cadastro.
          </p>
          <p>
            7.3 A exclusão ou anonimização dos seus dados pessoais estará
            limitada apenas aos itens que não tenham hipóteses de tratamento
            devidamente previstas nas legislações aplicadas. Para saber de que
            forma quais os dados poderão ser excluídos, você pode entrar em
            contato com nossa área de privacidade através do e-mail
            privacidade&#64;ambientabrasil.com.br
          </p>
        </div>
      </details>

      <details>
        <summary>8. CONTEÚDO E SISTEMAS DE TERCEIROS</summary>
        <div>
          <p>
            8.1 A Ambientá Brasil poderá apresentar links para sites e
            plataformas parceiras, deixando declarado que o conteúdo e políticas
            lá implementadas não são de nossa responsabilidade, cabendo a você
            avaliar os avisos de privacidade ou informações de segurança, e
            demais documentos.
          </p>
          <p>
            8.2 Você declara e reconhece que a responsabilidade por quaisquer
            perdas, danos ou prejuízos, diretos ou indiretos serão de terceiros
            que respondem pelos links, sites, conteúdos ou sistemas de
            terceiros.
          </p>
        </div>
      </details>

      <details>
        <summary>9. INDISPONIBILIDADE E USO INDEVIDO</summary>
        <div>
          <p>
            9.1 Tendo em vista, ainda, a impossibilidade de funcionamento
            integral e ininterrupto de qualquer sistema de telecomunicação ou de
            informática, durante 365 dias por ano, 24 horas por dia, e também
            pela dependência de serviços de telecomunicações prestados por
            terceiros, a Ambientá Brasil não garante, de nenhuma forma, a
            prestação do serviço de forma ininterrupta e/ ou isenta de erros.
          </p>
          <p>
            9.2 A Ambientá Brasil não se responsabiliza por quaisquer danos
            indiretos que decorram da navegação, nem por danos diretos ou
            indiretos decorrentes de uso inapropriado.
          </p>
        </div>
      </details>

      <details>
        <summary>10. DAS POLÍTICAS DE ENTREGA E DEVOLUÇÕES DE PRODUTOS</summary>
        <div>
          <p>
            10.1 Quando aplicável, e em decorrência das dimensões do produto,
            poderá ser exigido o prazo adicional de 48(quarenta e oito) horas
            para acomodação em sua embalagem para transporte.
          </p>
          <p>
            10.2 É sua responsabilidade verificar o estado do recebimento dos
            produtos adquiridos quando da entrega no momento do recebimento. Em
            caso de avarias no transporte orientamos que faça a recusa da
            mercadoria na nota fiscal, devolvendo o produto imediatamente ao
            transportador. Após a recusa, você terá o prazo de 48(quarenta e
            oito) horas úteis para comunicar a Ambientá Brasil. A empresa se
            responsabiliza em enviar um novo produtos de forma imediata.
            Ultrapassado esse prazo será considerado que os produtos foram
            entregues em perfeito estado.
          </p>
          <p>
            10.3 Respeitando a legislação brasileira vigente, você disporá do
            prazo de 7 (sete) dias para solicitar troca, ou devolução e
            posterior reembolso dos produtos adquiridos através do site, desde
            que os mesmos estejam na sua embalagem original e não contenha
            nenhum tipo de avaria.
          </p>
          <p>
            10.4 Os produtos adquiridos na modalidade "sob medida" ou
            personalizados, não serão passíveis de devolução conforme descrito
            na nossa Política de Troca e Cancelamento, e você terá direito a
            troca por outro produto semelhante.
          </p>
          <p>
            10.5 Em caso de devolução dos valores, o estorno somente será feito
            após o recebimento de todos os produtos na nossa sede e verificação
            da integralidade dos mesmos.
          </p>
          <p>
            10.6 Para solicitar a troca de qualquer produto, você deverá entrar
            em contato com o nosso setor específico que terá o prazo de
            48(quarenta e oito) horas ÚTEIS para retorno através do e-mail
            sac&#64;ambientabrasil
          </p>
        </div>
      </details>

      <details>
        <summary>11. PROPRIEDADE INTELECTUAL</summary>
        <div>
          <p>
            11.1 Todo o conteúdo acessado no site da Ambientá Brasil é protegido
            por direitos de propriedade intelectual e pertencem à RM
            Policarbonatos ou a seus parceiros.
          </p>
          <p>
            11.2 O uso de qualquer material e/ou conteúdo, em qualquer forma, é
            proibido, salvo se houver permissão expressa concedida pela RM
            Policarbonatos.
          </p>
        </div>
      </details>

      <details>
        <summary>12. SEGURANÇA DE DADOS</summary>
        <div>
          <p>
            12.1 A coleta de dados pessoais do usuário na plataforma/sistema
            possui como finalidade o processamento de contratos firmados entre
            as partes, em conformidade com o Aviso de Privacidade.
          </p>
          <p>
            12.2 Todos os dados pessoais são confidenciais, e serão tratados
            pela Ambientá Brasil de acordo com as regulamentações legais
            pertinentes, em especial a Lei Geral de Proteção de Dados, e o Marco
            Civil da Internet e demais legislações aplicáveis.
          </p>
        </div>
      </details>

      <details>
        <summary>13. ISENÇÃO DE RESPONSABILIDADE</summary>
        <div>
          <p>
            13.1 A Ambientá Brasil e seus agentes são isentos de qualquer
            responsabilidade relacionada ao acesso e uso do Site, excetuado os
            casos de dolo ou culpa grave, ou demais casos previstos em lei, e/ou
            danos resultantes de violação de obrigações contratuais essenciais.
          </p>
        </div>
      </details>

      <details>
        <summary>14. LEGISLAÇÃO APLICÁVEL E FORO COMPETENTEL</summary>
        <div>
          <p>
            14.1 Este Termo de Uso está fundamentado na legislação brasileira
            vigente.
          </p>
          <p>
            14.2 Ao acessar nosso Site, você concorda e entende que o foro
            escolhido para resolução de conflitos oriundos da utilização deste
            Site será o de Florianópolis/SC, com expressa renúncia de qualquer
            outro, por mais privilegiado que seja ou venha a ser.
          </p>
        </div>
      </details>

      <p>
        O presente Termo está disponível ao final de cada página no link “Termos
        e Condições", para acesso de qualquer consumidor, mesmo sem cadastro
        prévio no Site da Ambientá Brasil.
      </p>
      <p>
        O Aviso de Privacidade, pode ser encontrado também ao final de cada
        página do Site da Ambientá Brasil.
      </p>
      <p>Obrigado por chegar até aqui! Última atualização em 27/01/2023</p>
    </div>
  </div>
</div>
