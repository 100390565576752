export class StorageConstants {
  public static readonly AMBIENTA_CART_ITEMS = 'rmEcommerceCartItems';
  public static readonly AMBIENTA_CATEGORY = 'rmEcommerceCategory';
  public static readonly AMBIENTA_EMAIL_FORGOT = 'rmEcommerceEmailForgot';
  public static readonly AMBIENTA_FREIGHT = 'rmEcommerceFreight';
  public static readonly AMBIENTA_ID_PRODUCT = 'rmEcommerceIdProduct';
  public static readonly AMBIENTA_PRODUCT_FILTER = 'rmEcommerceProductFilter';
  public static readonly AMBIENTA_QUERY_SEARCH = 'rmEcommerceQuerySearch';
  public static readonly AMBIENTA_REQUEST_BUDGET = 'rmEcommerceRequestBudget';
  public static readonly AMBIENTA_SUB_CATEGORY = 'rmEcommerceSubCategory';
  public static readonly AMBIENTA_SUB_COUPON = 'rmEcommerceSubscriptionCoupon';
  public static readonly AMBIENTA_TOKEN = 'rmEcommerceToken';
  public static readonly AMBIENTA_TOKEN_FORGOT = 'rmEcommerceTokenForgot';
  public static readonly AMBIENTA_USER = 'rmEcommerceUser';
  public static readonly AMBIENTA_ZIP_CODE = 'rmEcommerceZipCode';
}
