import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InfoCategory } from '../../shared/models/app/info-category';
import { CartItem } from '../../shared/models/cart/cart';
import Checkout from '../../shared/models/checkout/checkout';
import Order from '../../shared/models/order/order';
import { Product } from '../../shared/models/product/product';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import { AppInfoService } from '../app-info/app-info.service';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(
    private appInfoService: AppInfoService,
    private httpService: HttpService,
    private localStorageService: LocalStorageService
  ) {}

  public checkout(checkout: Checkout): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`transactions/checkout/`, checkout).subscribe({
        next: async (res: any) => {
          await this.localStorageService.removeItem(
            StorageConstants.AMBIENTA_FREIGHT
          );
          const persianas: InfoCategory =
            await this.appInfoService.getCategoryPersianas();

          let newCartProducts: CartItem[] = [];
          const order = Object.assign(new Order(), res);

          order.cartProducts.forEach((item: any) => {
            let product: Product;
            product = new Product();
            product.fromJson(item.product, persianas);
            const cartItem: CartItem = { ...item, product };
            newCartProducts.push(cartItem);
          });
          order.cartProducts = newCartProducts;
          resolve(order);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao realizar seu pagamento. Entre em contato com a loja!';
          reject(msg);
        },
      });
    });
  }

  public statusPix(idOrder: number): Promise<boolean> {
    const headers = new HttpHeaders({
      'no-loader': 'no-loader',
    });

    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `transactions/status?orderId=${idOrder}`,
          {} as HttpParams,
          headers
        )
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar o status do PIX!';
            reject(msg);
          },
        });
    });
  }
}
