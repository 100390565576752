import { Injectable } from '@angular/core';
import { CouponData } from '../../shared/components/coupon-home/coupon-home.component';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService
  ) {}

  public async save(couponData: CouponData): Promise<boolean> {
    await this.localStorageService.set(
      StorageConstants.AMBIENTA_SUB_COUPON,
      JSON.stringify(new Date())
    );
    return new Promise((resolve, reject) => {
      this.httpService.post(`channel/coupon/subscribe/`, couponData).subscribe({
        next: (res: any) => {
          if (res) {
            resolve(true);
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            'Ocorreu um erro ao cadastrar os dados para Cupom!';
          reject(msg);
        },
      });
    });
  }
}
