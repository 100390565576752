<app-breadcrumb
  [path]="pathBreadcrumb"
  [subCategoryNew]="category"
  [model]="subCategory"
  [showSubCategoryNew]="showCategory"
  [showModel]="showSubCategory"
></app-breadcrumb>

<div class="customers-area-main">
  <div class="container">
    <div class="customers-area-main-content">
      <p-megaMenu [orientation]="'horizontal'" [model]="itemsMenu"></p-megaMenu>
      <router-outlet (activate)="setConfig($event)"></router-outlet>
    </div>
  </div>
</div>
