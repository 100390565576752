import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppInfoService } from '../../../services/app-info/app-info.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../../services/toast/toast.service';
import { AbstractInfoEnum } from '../../models/app/abstract-info-enum';
import { InfoCategory } from '../../models/app/info-category';
import {
  BreadcrumbComponent,
  BreadcrumbData,
} from '../breadcrumb/breadcrumb.component';
import { ModelsProductsComponent } from '../models-products/models-products.component';
import {
  ConfigSubCategories,
  ProductsShopComponent,
} from '../products-shop/products-shop.component';

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    BreadcrumbComponent,
    RouterOutlet,
    ProductsShopComponent,
  ],
  templateUrl: './sub-categories.component.html',
  styleUrl: './sub-categories.component.scss',
  providers: [ToastService, MessageService],
})
export class SubCategoriesComponent implements OnInit {
  public configSubCategories?: ConfigSubCategories;
  public persianas: InfoCategory = new InfoCategory();
  public subCategory: BreadcrumbData = new BreadcrumbData();
  public models: Array<AbstractInfoEnum> = [];
  public showBreadcrumbCat: boolean = true;
  public showProductsOnOffer: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appInfo: AppInfoService,
    private metaServices: MetaTagsService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.subCategory = this.setSubCategory(url[0]?.path);

      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CATEGORIES,
        this.subCategory.path,
        '',
        '',
        null
      );
    });
    this.configItens();
  }

  setSubCategory(path: string): BreadcrumbData {
    return {
      title: this.metaServices.getTitlePageSubCategory(path),
      path,
      id: path?.toUpperCase()?.replaceAll('-', '_'),
    };
  }

  setConfig(component: ModelsProductsComponent) {
    if (!this.subCategory.path) {
      this.activatedRoute.url.subscribe((url) => {
        this.subCategory = this.setSubCategory(url[0]?.path);
        component.subCategory = this.subCategory;
      });
    } else {
      component.subCategory = this.subCategory;
    }
    setTimeout(() => {
      this.showBreadcrumbCat = false;
    });
  }

  async configItens() {
    this.persianas = await this.appInfo.getCategoryPersianas();
    const subCategoryInfo = this.persianas?.subCategories?.find(
      (sub) => sub.id === this.subCategory.id
    );
    if (subCategoryInfo) {
      this.models = subCategoryInfo?.models || [];
    }
  }
}
