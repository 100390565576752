[
  {
    "id": "ROLO",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Persianas Rolo",
    "urlVideo": "https://www.youtube.com/embed/dV0einSDzWg?si=GjKM1DTgQJgGRWw0_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "1 - O que acompanha as Persianas rolo?",
          "contents": [
            "Enviamos tudo o que você precisa para instalar a persiana: suportes, buchas, parafusos e um manual completo."
          ]
        },
        {
          "title": "2 - Qual Persiana Rolo devo escolher para o meu ambiente e quais as diferenças?",
          "contents": [
            "Para te ajudar a escolher o melhor modelo para o seu ambiente nos preparamos uma descrição de cada um! <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-persianas.pdf\">Acesse clicando aqui.</a>"
          ]
        },
        {
          "title": "3 - Como instalar as Persianas Rolo?",
          "contents": [
            "A instalação é fácil e intuitiva, e você mesmo pode fazer!",
            "1. Meça e marque os pontos de fixação.",
            "2. Fixe os suportes na parede ou teto.",
            "3. Encaixe a persiana nos suportes.",
            "4. Verifique o nivelamento e alinhamento.",
            "5. Teste o mecanismo de abertura e fechamento.",
            "6. Faça ajustes finais, se necessário.",
            "Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\">clicando aqui</a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/dV0einSDzWg\">clicando aqui</a>."
          ]
        },
        {
          "title": "4 - Qual o prazo de fabricação da Persiana Rolo?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> as <a target=\"_blank\" href=\"/double-vision/translucido\">Persianas Double Vision</a> são produzidas sob medida para o seu ambiente!",
            "<strong>O prazo de fabricação é 5 a 7 dias úteis</strong>, mas fique tranquilo! Esse prazo já está sendo somado no prazo de entrega no momento da cotação do frete. ",
            "Data que você verá em tela, será a data final para a persiana chegar no seu endereço!"
          ]
        },
        {
          "title": "5 - Caso duas Persianas Rolo sejam instaladas lado a lado, qual será o espaço sem tecido entre elas?",
          "contents": [
            "Instalando as persianas lado a lado, de forma que os suportes delas se encostem, o espaço sem tecido entre elas será 3cm a 3,5 cm."
          ]
        },
        {
          "title": "6 - Quais são as cores dos bandôs e comandos das Persianas Rolo?",
          "contents": [
            "As Persianas Rolo possuem como opcional bandô de acabamento e estão disponíveis em duas cores. Se desejar, basta adquirir um Bandô com as mesmas medidas da persiana escolhida. E não se preocupe com a instalação, o Bandô já vem pronto para uso junto com a persiana, por isso, ao optar pelos acabamentos, cada persiana acompanhará acessórios (bandô e comando) de acordo com a tonalidade do tecido escolhido, como apresentado na relação abaixo:",
            "Persiana Rolo Branca - acompanha base inferior e acessórios na cor BRANCA",
            "Persiana Rolo Bege - acompanha base inferior e acessórios na cor BRANCA",
            "Persiana Rolo Cinza - acompanha base inferior e acessórios na cor BRANCA",
            "Persiana Rolo Preta - acompanha base inferior e acessórios na cor PRETA"
          ]
        },
        {
          "title": "7 - Como devem ser tiradas as medidas para instalação fora do vão da janela?",
          "contents": [
            "Para instalações de persianas fora do vão da janela, meça a largura e altura da janela e, ao fazer o pedido, adicione pelo menos 10cm a cada uma das dimensões das persianas."
          ]
        },
        {
          "title": "8 - Como devem ser tiradas as medidas para instalação dentro do vão da janela?",
          "contents": [
            "Para instalações de persianas dentro do vão da janela, meça as dimensões da janela e, ao fazer o pedido, reduza 1cm da largura e da altura das persianas."
          ]
        },
        {
          "title": "9 - Posso automatizar o controle das persianas Rolo?",
          "contents": [
            "Sim! Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> você irá encontrar os anúncios por padrão com <strong>acionamento manual</strong>, mas no momento da compra é possível selecionar a opção de <strong>motorização</strong>. ",
            "<strong>As opções que temos são: </strong>",
            "<strong>1 - Acionamento manual:</strong> o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça. ",
            "<strong>2 - Acionamento motorizado:</strong> permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
          ]
        },
        {
          "title": "10- Qual a garantia das persianas?",
          "contents": [
            "A garantia para as Persianas Rolo é de 2 anos, cobrindo defeitos de fabricação. Esta garantia se aplica apenas aos produtos que possuam a etiqueta de fabricação e a identificação da empresa. É importante ressaltar que a garantia é efetivada somente na fábrica, não sendo viável o deslocamento de técnicos para realizar assistência no local."
          ]
        }
      ]
    }
  },
  {
    "id": "DOUBLE_VISION",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Persianas Double Vision",
    "urlVideo": "https://www.youtube.com/embed/xQOhQh_EWbQ?si=_R3G98r4-EEUGPXs_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "1 - O que acompanha as Persianas Double Vision?",
          "contents": [
            "Enviamos tudo o que você precisa para instalar a persiana: suportes, buchas, parafusos e um manual completo."
          ]
        },
        {
          "title": "2 - Qual Persiana Double Vision devo escolher para o meu ambiente e quais as diferenças?",
          "contents": [
            "Para te ajudar a escolher o melhor modelo para o seu ambiente nós preparamos uma descrição de cada um! <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-persianas.pdf\">Acesse clicando aqui!</a>"
          ]
        },
        {
          "title": "3- Qual o prazo de fabricação da Persiana Double Vision?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> as Persianas Double Vision são produzidas sob medida para o seu ambiente! ",
            "<strong>O prazo de fabricação é 5 a 7 dias úteis</strong>, mas fique tranquilo! Esse prazo já está sendo somado no prazo de entrega no momento da cotação do frete. ",
            "Data que você verá em tela, será a data final para a persiana chegar no seu endereço!"
          ]
        },
        {
          "title": "4 - Como Funcionam as Persianas Double Vision?",
          "contents": [
            "Este modelo possui tecidos sobrepostos em listras de tecido opaco que se intercalam com tela. Une Blackout + Translucido permitindo flexibilidade no controle da luz sem ter que recolher a persiana por completo.",
            "Ao ajustar as camadas de tecido, é possível obter diferentes níveis de privacidade, mantendo também a visão externa."
          ]
        },
        {
          "title": "5 - Quais são as cores dos bandôs e comandos das Persianas Double Vision?",
          "contents": [
            "As Persianas Double Vision possuem como opcional bandô de acabamento e estão disponíveis em quatro cores. ",
            "Por isso, ao optar pelos acabamentos, cada persiana acompanhará acessórios (bandô e comando) de acordo com a tonalidade do tecido escolhido, como apresentado na relação abaixo:",
            "Persiana Double Vision Branca - acompanha base inferior e acessórios na cor BRANCA",
            "Persiana Double Vision Bege - acompanha base inferior e acessórios na cor BRANCA",
            "Persiana Double Vision Cinza - acompanha base inferior e acessórios na cor BRANCA",
            "Persiana Double Vision Preta - acompanha base inferior e acessórios na cor PRETA"
          ]
        },
        {
          "title": "6 - Caso duas Persianas Rolo sejam instaladas lado a lado, qual será o espaço sem tecido entre elas?",
          "contents": [
            "Instalando as persianas lado a lado, de forma que os suportes delas se encostem, o espaço sem tecido entre elas será 3cm a 3,5 cm."
          ]
        },
        {
          "title": "7 - Como faço para manter e limpar as persianas Double Vision?",
          "contents": [
            "A limpeza é simples. Utilize um aspirador de pó e se necessário um pano levemente úmido. Evite produtos abrasivos."
          ]
        },
        {
          "title": "8 - Posso automatizar o controle das persianas Double Vision?",
          "contents": [
            "Sim! Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> você irá encontrar os anúncios por padrão com <strong>acionamento manual</strong>, mas no momento da compra é possível selecionar a opção de <strong>motorização</strong>. ",
            "<strong>As opções que temos são:</strong>",
            "<strong>1 - Acionamento manual:</strong> o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça. ",
            "<strong>2 - Acionamento motorizado:</strong> permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
          ]
        },
        {
          "title": "9 - Qual a garantia das Persianas Double Vision?",
          "contents": [
            "A garantia para as Persianas Double Vision é de 2 anos, cobrindo defeitos de fabricação. Esta garantia se aplica apenas aos produtos que possuam a etiqueta de fabricação e a identificação da empresa. É importante ressaltar que a garantia é efetivada somente na fábrica, não sendo viável o deslocamento de técnicos para realizar assistência no local."
          ]
        },
        {
          "title": "10 - Como instalar as Persianas Double Vision?",
          "contents": [
            "A instalação é fácil e intuitiva, e você mesmo pode fazer!",
            "1 - Meça e marque os pontos de fixação.",
            "2 - Fixe os suportes na parede ou teto.",
            "3 - Encaixe a persiana nos suportes.",
            "4 - Verifique o nivelamento e alinhamento.",
            "5 - Teste o mecanismo de abertura e fechamento.",
            "6 - Faça ajustes finais, se necessário.",
            "Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf\">clicando aqui</a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/xQOhQh_EWbQ\">clicando aqui</a>."
          ]
        }
      ]
    }
  },
  {
    "id": "ROMANA",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Persianas Romana",
    "urlVideo": "https://www.youtube.com/embed/LA4NNXoFzOY?si=fHiLBhw2is9t0zrN_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "1 - O que acompanha as Persianas Romana?",
          "contents": [
            "Enviamos tudo o que você precisa para instalar a persiana: suportes, buchas, parafusos e um manual completo."
          ]
        },
        {
          "title": "2 - As Persianas Romana Possuem Bandô de acabamento?",
          "contents": [
            "As persianas romanas não possuem bandô de acabamento, mas o seu acabamento é slim e discreto. O bandô pode ser adicionado nos modelos Rolo e Double Vision."
          ]
        },
        {
          "title": "3 - Qual Persiana Romana devo escolher para o meu ambiente e quais as diferenças?",
          "contents": [
            "Para te ajudar a escolher o melhor modelo para o seu ambiente nos preparamos uma descrição de cada um!  <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-persianas.pdf\">Acesse clicando aqui!</a>"
          ]
        },
        {
          "title": "4 - Como instalar as Persianas Romana?",
          "contents": [
            "A instalação é fácil e intuitiva, e você mesmo pode fazer!",
            "1 - Meça e marque os pontos de fixação.",
            "2 - Fixe os suportes na parede ou teto.",
            "3 - Encaixe a persiana nos suportes.",
            "4 - Verifique o nivelamento e alinhamento.",
            "5 - Teste o mecanismo de abertura e fechamento.",
            "6 - Faça ajustes finais, se necessário.",
            "Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf\">clicando aqui</a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/LA4NNXoFzOY\">clicando aqui</a>."
          ]
        },
        {
          "title": "5 - Qual o prazo de fabricação da Persiana Romana?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> as Persianas Romanas são produzidas sob medida para o seu ambiente! O prazo de fabricação é 5 a 7 dias úteis, mas fique tranquilo! Esse prazo já está sendo somado no prazo de entrega no momento da cotação do frete. Data que você verá em tela, será a data final para a persiana chegar no seu endereço!"
          ]
        },
        {
          "title": "6 - Caso duas Persianas Romana sejam instaladas lado a lado, qual será o espaço sem tecido entre elas?",
          "contents": [
            "Instalando as persianas lado a lado, de forma que os suportes delas se encostem, o espaço sem tecido entre elas será 3cm a 3,5 cm."
          ]
        },
        {
          "title": "7 - Como devem ser tiradas as medidas para instalação fora do vão da janela?",
          "contents": [
            "Para instalações de persianas fora do vão da janela, meça a largura e altura da janela e, ao fazer o pedido, adicione pelo menos 10cm a cada uma das dimensões das persianas."
          ]
        },
        {
          "title": "8 - Como devem ser tiradas as medidas para instalação dentro do vão da janela?",
          "contents": [
            "Para instalações de persianas dentro do vão da janela, meça as dimensões da janela e, ao fazer o pedido, reduza 1cm da largura e da altura das persianas."
          ]
        },
        {
          "title": "9 - Posso automatizar o controle das persianas Romana?",
          "contents": [
            "Sim! Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> você irá encontrar os anúncios por padrão com acionamento manual, mas no momento da compra é possível selecionar a opção de motorização. ",
            "As opções que temos são: ",
            "1 - Acionamento manual: o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça. ",
            "2 - Acionamento motorizado: permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
          ]
        },
        {
          "title": "10- Qual a garantia das persianas",
          "contents": [
            "A garantia para as Persianas Romana é de 2 anos, cobrindo defeitos de fabricação. Esta garantia se aplica apenas aos produtos que possuam a etiqueta de fabricação e a identificação da empresa. É importante ressaltar que a garantia é efetivada somente na fábrica, não sendo viável o deslocamento de técnicos para realizar assistência no local."
          ]
        }
      ]
    }
  }
]