import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, isPlatformBrowser, NgClass } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGModule } from '../../../primeng.module';
import { CartService } from '../../../services/cart/cart.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../../services/product/products.service';
import { ToastService } from '../../../services/toast/toast.service';
import { WhatsAppService } from '../../../services/whats-app/whats-app.service';
import { AbstractInfoEnum } from '../../models/app/abstract-info-enum';
import { InfoCategory } from '../../models/app/info-category';
import { CategoriesEnum, SubCategoriesEnum } from '../../models/app/info-enum';
import InfoSubCategory from '../../models/app/info-sub-category';
import { CartItem } from '../../models/cart/cart';
import { PageResponse } from '../../models/page/page-response';
import { Product } from '../../models/product/product';
import ProductFilter from '../../models/product/product-filter';
import { SharedModule } from '../../shared.module';
import { HasNoItensComponent } from '../has-no-itens/has-no-itens.component';
import {
  ProductShopOrder,
  ProductsShopOrderComponent,
} from '../products-shop-order/products-shop-order.component';

export class FilterProductsShop {
  public filterSelected?: AbstractInfoEnum;
  public name?: string;
  public title?: string;
  public values?: FilterValueProductsShop[];
  public visible = true;
}

export class FilterValueProductsShop {
  public description?: string;
  public id?: string;
  public title?: string;
}
export class FilterAttributes {
  public actuationSide?: AbstractInfoEnum;
  public actuationType?: AbstractInfoEnum;
  public aluminumWallThickness?: AbstractInfoEnum;
  public brand?: AbstractInfoEnum;
  public capabilitie?: AbstractInfoEnum;
  public color?: AbstractInfoEnum;
  public colorCode?: AbstractInfoEnum;
  public fabricComposition?: AbstractInfoEnum;
  public finish?: AbstractInfoEnum;
  public heightThickness?: AbstractInfoEnum;
  public length?: AbstractInfoEnum;
  public model?: AbstractInfoEnum;
  public potencie?: AbstractInfoEnum;
  public speed?: AbstractInfoEnum;
  public unitsCommercialize?: AbstractInfoEnum;
  public voltage?: AbstractInfoEnum;
  public width?: AbstractInfoEnum;
}

export class ConfigSubCategories {
  public persianas?: InfoCategory;
  public subCategorySelected: InfoSubCategory | undefined;
  public modelSelected: AbstractInfoEnum | undefined;
  public productsOnOffer: boolean = false;
  public productsQuerySearch: string | undefined;
}

@Component({
  selector: 'app-products-shop',
  standalone: true,
  imports: [
    NgClass,
    PrimeNGModule,
    ProductsShopOrderComponent,
    SharedModule,
    HasNoItensComponent,
  ],
  templateUrl: './products-shop.component.html',
  styleUrl: './products-shop.component.scss',
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProductsShopComponent {
  @Input()
  public set configSubCategories(
    configSubCategories: ConfigSubCategories | undefined
  ) {
    if (configSubCategories) {
      this.firstTime = true;
      this.persianas = configSubCategories.persianas;
      this.subCategorySelected = configSubCategories.subCategorySelected;
      this.modelSelected = configSubCategories.modelSelected;
      this.productsOnOffer = configSubCategories.productsOnOffer;
      this.productsQuerySearch = configSubCategories.productsQuerySearch;
      this.setResize();
      if (this.productsOnOffer) {
        this.configProductOnOffer();
      } else if (this.productsQuerySearch) {
        this.configProductQuerySearch();
      } else {
        this.configFilterProducts();
      }
    }
  }

  public persianas?: InfoCategory;
  public subCategorySelected: InfoSubCategory | undefined;
  public modelSelected: AbstractInfoEnum | undefined;
  public filterProductsShop: FilterProductsShop[] = [];
  public page: number = 0;
  public pageResponse: PageResponse | undefined;
  public products: Array<Product> = [];
  public productFilter: ProductFilter = new ProductFilter();
  public productsQuerySearch: string | undefined;
  public productsOnOffer: boolean = false;
  public showFilterMobile = true;
  public isMobile = false;
  public firstTime = true;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cartService: CartService,
    private loaderService: LoaderService,
    private metaTagService: MetaTagsService,
    private productsService: ProductsService,
    public router: Router,
    private toastService: ToastService,
    private whatsAppService: WhatsAppService
  ) {}

  configFilterProductShop() {
    this.filterProductsShop = [];

    let filterColor: FilterProductsShop = new FilterProductsShop();
    this.subCategorySelected?.getFilterSubCategoryByName('widths');
    filterColor.name = 'color';
    filterColor.title = 'CORES';
    filterColor.values = this.getFilterColor();
    filterColor.visible = true;
    this.filterProductsShop.push(filterColor);
    this.firstTime = false;
  }

  getFilterColor(): FilterValueProductsShop[] {
    const uniqueColors = Array.from(
      new Map(
        this.products.map((product) => [
          product.color,
          product.colorDescription,
        ])
      ).entries()
    );

    return uniqueColors
      .map(([color, colorDescription]) => ({
        description: colorDescription,
        id: color,
        title: colorDescription,
      }))
      .sort((a, b) => (a.description ?? '').localeCompare(b.description ?? ''));
  }

  configProductOnOffer() {
    this.productFilter.withDiscountOrFeatured = true;
    this.productFilter.discount = 5;
    this.productFilter.perPage = 30;
    this.getProducts();
  }

  configProductQuerySearch() {
    this.productFilter.querySearch = this.productsQuerySearch;
    this.productFilter.perPage = 30;
    this.getProducts();
  }

  configFilterProducts() {
    this.productFilter.categories = [CategoriesEnum.PERSIANAS];
    this.productFilter.subCategories = this.getSubCategoriaSelected();
    this.productFilter.perPage =
      this.subCategorySelected?.isSubCategoryPerSquareMeter() ? 50 : 16;

    if (this.isCategoryAmbientes()) {
      this.productFilter.models = this.modelSelected?.filterModels;
    } else {
      this.productFilter.model = this.modelSelected?.id;
    }
    this.getProducts();
  }

  getSubCategoriaSelected() {
    if (this.isCategoryAmbientes()) {
      return this.modelSelected?.filterSubCategories;
    } else {
      return [this.subCategorySelected?.id || ''];
    }
  }

  async getProducts() {
    this.showFilterMobile = false;
    this.toTop();
    this.loaderService.showLoader();
    try {
      if (!this.productFilter.direction) {
        this.productFilter.direction = 'ASC';
      }
      this.productFilter.categories = [CategoriesEnum.PERSIANAS];
      this.productFilter.order = ['value'];
      this.productFilter.page = this.page;
      this.productFilter.inactive = false;
      this.productFilter.productPerSquareMeter = this.categoryPerSquareMeter();
      this.productFilter.ignoreMainVariation = this.haveFilterToVariation();
      this.products = [];
      this.pageResponse = await this.productsService.products(
        this.productFilter
      );

      if ((this.pageResponse?.totalElements || 0) > 0) {
        this.products = this.pageResponse?.content || [];
        this.products = this.products.filter((p) => this.showProduct(p));

        if (this.firstTime) {
          this.configFilterProductShop();
        }
        this.setAriaLabel();
      }
    } catch (err) {
      this.toastService.error('Não existe produto para o filtro selecionado!');
    } finally {
      this.loaderService.hideLoader();
    }
  }

  haveFilterToVariation() {
    return true;
  }

  categoryPerSquareMeter() {
    return (
      this.isCategoryAmbientes() ||
      this.subCategorySelected?.id === SubCategoriesEnum.ROLO ||
      this.subCategorySelected?.id === SubCategoriesEnum.DOUBLE_VISION ||
      this.subCategorySelected?.id === SubCategoriesEnum.ROMANA ||
      this.subCategorySelected?.id === SubCategoriesEnum.ROMANA_DE_TETO ||
      this.subCategorySelected?.id === SubCategoriesEnum.HORIZONTAL ||
      this.subCategorySelected?.id === SubCategoriesEnum.PAINEL
    );
  }

  private showProduct(prod: Product): boolean {
    let show = prod.enabled;
    if (
      prod.stockBalance === 0 &&
      (prod.daysOnlyOrder === 0 || !prod.daysOnlyOrder)
    ) {
      show = false;
    }
    return show;
  }

  getTitleModel() {
    return this.isCategoryAmbientes() ? 'AMBIENTES' : 'MODELOS';
  }

  onClickOpenProduct(url: string | undefined) {
    if (url) {
      this.router.navigate([url]);
    }
  }

  async onClickAddToCart(product: Product) {
    if (product) {
      if (product.isPersiana()) {
        return this.onClickOpenProduct(product.url);
      }
      try {
        if (!product.enabled) {
          return this.toastService.error(
            `Produto ${product.id} não pode ser adicionado ao seu carrinho pois está inativo!`
          );
        }

        if (
          product.qtd > product.stockBalance &&
          !product.canBeGeneratedWithStockZero
        ) {
          return this.toastService.error(
            `Existe apenas ${product.stockBalance} item(s) disponível(is)`
          );
        }

        this.loaderService.showLoader();

        const cartItem: CartItem = {
          productId: product.id,
          amount: product.qtd || 1,
          product,
        };

        setTimeout(async () => {
          try {
            await this.cartService.addItem(cartItem, true);
            this.toastService.success(
              'Produto adicionado ao carrinho com sucesso!',
              2000
            );
          } catch (error: any) {
            this.toastService.error(error);
          } finally {
            this.loaderService.hideLoader();
          }
        }, 500);
      } catch (error) {
        this.loaderService.hideLoader();
        this.toastService.error(
          'Ocorreu um erro ao tentar adicionar seu produto ao carrinho!'
        );
      }
    }
  }

  onClickBuyByWhatss(product: Product) {
    if (product) {
      this.whatsAppService.setOpenWhatsAppProduct(product);
    }
  }

  onClickModel() {
    this.page = 0;
    const modelSearch = this.productFilter?.model;
    if (modelSearch !== this.modelSelected?.id) {
      const path = this.modelSelected?.path;
      if (path) {
        this.productFilter = new ProductFilter();
        this.router.navigate([path]);
      }
    }
  }

  onClickFilter(filter: FilterProductsShop) {
    this.page = 0;
    const name = filter.name || '';
    const value = filter?.filterSelected?.id || '';
    const filterProduct = Object.getOwnPropertyDescriptors(this.productFilter);
    const prop = filterProduct[name];
    if (prop?.value !== value) {
      this.productFilter.setValue(name, value);
      this.getProducts();
    } else {
      filter.filterSelected = undefined;
      this.productFilter.setValue(name, '');
      this.getProducts();
    }
  }

  isKit(product: Product): boolean {
    return product.productType === 2;
  }

  onPageChange(event: any) {
    this.page = event.page;
    this.getProducts();
  }

  showPagination() {
    return (this.products?.length || 0) > 0 && !this.categoryPerSquareMeter();
  }

  toTop() {
    this.dom.body.scrollTop = 0; // Safari
    this.dom.documentElement.scrollTop = 0; // Other browsers
  }

  changedOrderProducts(filter: ProductShopOrder) {
    this.productFilter.direction = '';
    if (filter) {
      this.productFilter.direction = filter.orderBy;
    }
    this.getProducts();
  }

  addOverflowHiddenToBody(add: boolean) {
    this.metaTagService.classToBody('overflow-hidden', add);
  }

  setAriaLabel() {
    setTimeout(() => {
      var element = this.dom.getElementsByClassName('p-paginator-first');
      if (element.length > 0) {
        element[0].ariaLabel = 'Primeira página';
      }

      element = this.dom.getElementsByClassName('p-paginator-prev');
      if (element.length > 0) {
        element[0].ariaLabel = 'Página anterior';
      }

      element = this.dom.getElementsByClassName('p-paginator-next');
      if (element.length > 0) {
        element[0].ariaLabel = 'Próxima página';
      }

      element = this.dom.getElementsByClassName('p-paginator-last');
      if (element.length > 0) {
        element[0].ariaLabel = 'Última página';
      }
    }, 500);
  }

  isCategoryAmbientes() {
    return this.subCategorySelected?.id === CategoriesEnum.AMBIENTES;
  }

  setResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window?.innerWidth < 640;
    }
  }
}
