import { ProductsService } from '../../../services/product/products.service';
import { InfoCategory } from '../app/info-category';
import FreightSelected from '../freight/freight-selected';
import CartCheckout from './cart-checkout';

export default class CartCheckoutDetails {
  public cart: CartCheckout;
  public discount: number;
  public freightSelected: FreightSelected | undefined;
  public gross: number;
  public total: number;

  constructor(
    obj: any,
    persianas: InfoCategory,
    public productsService: ProductsService
  ) {
    this.cart = new CartCheckout(productsService);
    this.cart.fromJson(obj?.cart, persianas);
    this.discount = obj?.discount;
    this.freightSelected = new FreightSelected(
      obj?.freightSelected?.freight,
      obj?.freightSelected?.freight?.tokenOffer
    );
    this.freightSelected.optionFreight = obj?.freightSelected?.optionFreight;
    this.gross = obj?.gross;
    this.total = obj?.total;
  }
}
