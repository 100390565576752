<p-toast position="top-center" [baseZIndex]="99999"></p-toast>
<div
  class="whats-component"
  [ngClass]="{
    'whats-component-with-product': (productWhatsApp?.id || 0) > 0
  }"
>
  <section class="whats-component-section">
    <button class="whats-btn-close" (click)="onClickBtnClose()">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 348.333 348.334"
      >
        <title>Fechar</title>
        <path
          d="M336.559,68.611L231.016,174.165l105.543,105.549c15.699,15.705,15.699,41.145,0,56.85
                          c-7.844,7.844-18.128,11.769-28.407,11.769c-10.296,0-20.581-3.919-28.419-11.769L174.167,231.003L68.609,336.563
                          c-7.843,7.844-18.128,11.769-28.416,11.769c-10.285,0-20.563-3.919-28.413-11.769c-15.699-15.698-15.699-41.139,0-56.85
                          l105.54-105.549L11.774,68.611c-15.699-15.699-15.699-41.145,0-56.844c15.696-15.687,41.127-15.687,56.829,0l105.563,105.554
                          L279.721,11.767c15.705-15.687,41.139-15.687,56.832,0C352.258,27.466,352.258,52.912,336.559,68.611z"
        ></path>
      </svg>
    </button>
    <div class="whats-header">
      <h4>ambientabrasil.com.br</h4>
      <h5>online</h5>
    </div>
    <div class="whats-main">
      <p *ngIf="productWhatsApp">
        Desejo mais informações sobre o produto:
        {{ productWhatsApp.name }}
      </p>
      <p *ngIf="!productWhatsApp">
        Deseja mais informações? Fale agora com um dos nossos consultores por
        WhatsApp
      </p>
      <form
        role="form"
        method="post"
        name="whats-form"
        (ngSubmit)="onClickSendWhatsApp()"
      >
        <input
          type="text"
          name="name"
          pInputText
          [(ngModel)]="whatsAppData.name"
          placeholder="Digite seu nome"
          [ngClass]="{
            'error-input': whatsAppError.errorName
          }"
        />
        <p-inputMask
          name="phone"
          mask="(99) 99999-9999"
          [(ngModel)]="whatsAppData.phone"
          placeholder="Digite seu telefone com DDD"
          [ngClass]="{
            'error-input-mask': whatsAppError.errorPhone
          }"
        ></p-inputMask>
        <p-inputMask
          name="zipCode"
          mask="99999-999"
          [(ngModel)]="whatsAppData.cep"
          placeholder="Digite seu CEP"
          [ngClass]="{
            'error-input-mask': whatsAppError.errorCep
          }"
        ></p-inputMask>
        <p>
          Ao informar meus dados, estou ciente das diretrizes do
          <a
            title="Aviso de Privacidade"
            href="/aviso-de-privacidade"
            target="_blank"
            rel="noopener"
          >
            Aviso de Privacidade.</a
          >
        </p>
        <button type="submit">Iniciar a conversa</button>
      </form>
    </div>
  </section>
</div>
