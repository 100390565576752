<app-breadcrumb [path]="'Catálogos'" [showTitle]="true"></app-breadcrumb>

<div class="catalog-main">
  <div class="container">
    <div class="catalog-main-content">
      <div class="catalog-item" *ngFor="let item of catalogs">
        <a href="{{ item.url }}" target="_blank">
          <p>
            {{ item.title }}
            <img
              src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/pdf.png"
              alt="Faça o donwload do manual e PDF"
              width="25"
              height="25"
            />
          </p>
        </a>
      </div>
    </div>
  </div>
</div>
