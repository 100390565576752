import { DOCUMENT, NgClass, NgFor } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import moreInformation from '../../../../assets/more-information/more-information.json';
import { PrimeNGModule } from '../../../primeng.module';
import InfoSubCategory from '../../models/app/info-sub-category';
import { SharedModule } from '../../shared.module';

export class MoreInformation {
  public id?: string;
  public informations?: MoreInformationItem[];
}

export class MoreInformationItem {
  public img?: string;
  public contents?: string[];
  public title?: string;
}

@Component({
  selector: 'app-more-information',
  standalone: true,
  imports: [NgFor, NgClass, PrimeNGModule, SharedModule],
  templateUrl: './more-information.component.html',
  styleUrl: './more-information.component.scss',
})
export class MoreInformationComponent {
  @Input()
  public set subCategory(subCategory: InfoSubCategory | undefined) {
    if (subCategory) {
      this.setInformations(subCategory);
    }
  }

  public moreInformations: MoreInformationItem[] | undefined;
  public showAllInformations = false;

  constructor(@Inject(DOCUMENT) private dom: any) {}

  setInformations(subCategory: InfoSubCategory) {
    if (moreInformation) {
      let moreInfo = moreInformation.filter(
        (info) => info.id === subCategory.id
      );

      if (moreInfo) {
        const info: MoreInformation = Object.assign(
          new MoreInformation(),
          moreInfo[0]
        );
        this.moreInformations = info.informations;
      }
    }
  }

  goToElement(element: HTMLElement) {
    const offsetTop = element.offsetTop;
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth', // Para ter uma rolagem suave
    });
  }
}
