import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { Menubar } from 'primeng/menubar';
import { PrimeNGModule } from '../../../primeng.module';
import { AppInfoService } from '../../../services/app-info/app-info.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { CategoriesEnum } from '../../models/app/info-enum';
import InfoSubCategory from '../../models/app/info-sub-category';

@Component({
  selector: 'app-menu-bar',
  standalone: true,
  imports: [PrimeNGModule],
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements AfterViewInit {
  public subCategories: Array<InfoSubCategory> = [];
  public items: MenuItem[] = [];

  constructor(
    private appInfoService: AppInfoService,
    private metaTagService: MetaTagsService,
    private ngZone: NgZone,
    public router: Router,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  ngOnInit() {
    this.appInfoService.getCompleteAppInfo().subscribe((data) => {
      if (data) {
        this.init();
      }
    });
  }

  async init() {
    this.subCategories =
      await this.appInfoService.getSubCategoriesWithProductActive();
    this.buildMenu();
  }

  private buildMenu() {
    const newMenu: MenuItem[] =
      this.subCategories?.map((sub) => this.createMenuItem(sub)) || [];
    this.appendAdditionalMenuItems(newMenu);
    this.items = newMenu;
  }

  private createMenuItem(sub: InfoSubCategory): MenuItem {
    const catLink = sub?.id?.replaceAll('_', '-')?.toLowerCase();

    return {
      id: sub.id,
      label: sub.description,
      url:
        sub.id !== CategoriesEnum.AMBIENTES
          ? `${catLink}/todos-modelos`
          : undefined,
      target: '_self',
      items: sub.models?.map((model) => ({
        id: model.id,
        label: model.description,
        url: `${catLink}/${model?.id?.replaceAll('_', '-')?.toLowerCase()}`,
        target: '_self',
        command: (e) => this.takeMenuAction(e),
      })),
    };
  }

  private appendAdditionalMenuItems(menu: MenuItem[]) {
    const additionalItems: MenuItem[] = [
      {
        label: 'Fale Conosco',
        routerLink: 'fale-conosco',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Catálogos',
        routerLink: 'catalogos',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Políticas, Trocas e Cancelamentos',
        routerLink: 'politicas-trocas-cancelamentos',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Termos e Condições',
        routerLink: 'termos-condicoes-uso',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Aviso de Privacidade',
        routerLink: 'aviso-de-privacidade',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Sobre Nós',
        routerLink: 'quem-somos',
        styleClass: 'mobile-visible',
      },
    ];
    menu.push(...additionalItems);
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.setupMenuAccessibility();
    });
  }

  private setupMenuAccessibility() {
    this.setupMenuBarButton();
    this.setupSubMenuAccessibility();
  }

  private setupMenuBarButton() {
    const element = this.dom.querySelector('.p-menubar-button');
    if (element) {
      element.setAttribute('href', '/');
      element.setAttribute(
        'aria-label',
        'Navegue pelo nosso menu e encontre nossos produtos!'
      );
    }
  }

  private setupSubMenuAccessibility() {
    this.subCategories?.forEach((sub, index) => {
      const itemContainer = this.dom.getElementById(sub.id || '');
      const item = itemContainer?.querySelector('.p-menuitem-text');
      const itemLink = itemContainer?.querySelector('a');

      if (itemContainer) {
        itemContainer.setAttribute(
          'aria-label',
          `${sub.description} - ${index}`
        );
        this.removeUnwantedAttributes(itemContainer);
      }
      item?.setAttribute('aria-label', sub.description || '');
      itemLink?.setAttribute('role', 'button');

      const itemSubmenu = itemContainer?.querySelector('.p-submenu-list');
      itemSubmenu?.setAttribute('id', `submenu-${index}`);
    });
  }

  private removeUnwantedAttributes(element: Element) {
    ['aria-haspopup', 'aria-level', 'aria-setsize', 'aria-posinset'].forEach(
      (attr) => element.removeAttribute(attr)
    );
  }

  takeMenuAction(event: MenuItemCommandEvent) {
    const url = event?.item?.url || '';
    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  onClickMenu(e: MouseEvent) {
    const isMenuMobileOpen = this.dom.getElementsByClassName(
      'p-menubar-mobile-active'
    );
    if (isMenuMobileOpen?.length > 0) {
      e.preventDefault();
      this.addOverflowHiddenToBody(isMenuMobileOpen.length > 0);
    }
  }

  addOverflowHiddenToBody(add: boolean, menubar?: Menubar) {
    menubar?.hide();
    this.metaTagService.classToBody('overflow-hidden', add);
  }
}
