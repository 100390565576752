import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';

export const homeResolver: ResolveFn<any> = (route, state) => {
  const videos = [
    {
      id: 'vaFLqrCvSYo',
      src: 'https://www.youtube.com/embed/vaFLqrCvSYo?si=95xR0ghpRby4Ad5T&amp;controls=0',
      title: 'Persiana Rolô Screen 3%',
      url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/ambienta-manual-de-instalacao-persianas.pdf',
    },
    {
      id: 'Qbs71GWjpMk',
      src: 'https://www.youtube.com/embed/Qbs71GWjpMk?si=EIkQ94l4PsXGY79m&amp;controls=0',
      title: 'Persiana Rolô Napolis BK',
      url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/ambienta-manual-de-instalacao-persianas.pdf',
    },
    {
      id: 'D8MM9xUTl8k',
      src: 'https://www.youtube.com/embed/D8MM9xUTl8k?si=l0z1kmg3IsTAP-PI&amp;controls=0',
      title: 'Persiana Rolô Pinpoint',
      url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/ambienta-manual-de-instalacao-persianas.pdf',
    },
  ];

  return of({ videos });
};
