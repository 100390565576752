import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-slider-swiper',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './slider-swiper.component.html',
  styleUrls: ['./slider-swiper.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SliderSwiperComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.setResize();
    }
  }

  public screenWidth?: number;
  public isMobile = false;
  public breakpoints = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.breakpoints = {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      },
    };
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.setResize();
    }
  }

  setResize() {
    this.screenWidth = window.innerWidth;
    this.isMobile = this.screenWidth < 768;
  }
}
