import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ContactData } from '../../shared/components/contact-form/contact-form.component';
import { WhatsAppData } from '../../shared/components/whats-app/whats-app.component';
import { Cart, CartItem } from '../../shared/models/cart/cart';
import CartCheckoutDetails from '../../shared/models/cart/cart-checkout-details';
import CartProductCheckout from '../../shared/models/cart/cart-product-checkout';
import FreightSelected from '../../shared/models/freight/freight-selected';
import Order from '../../shared/models/order/order';
import { Product } from '../../shared/models/product/product';
import User from '../../shared/models/user/user';
import { AuthService } from '../auth/auth.service';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  public user: User | undefined = new User();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public authService: AuthService
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  sendEvent(event: string, params = {}) {
    let canSendEvent =
      isPlatformBrowser(this.platformId) &&
      !this.user?.isAdmin &&
      window.location.hostname != 'localhost';
    if (canSendEvent) {
      gtag('event', event, params);
    }
  }

  sendEventAttToCart(cartItem: CartItem) {
    const params = {
      currency: 'BRL',
      value: cartItem?.product?.value?.toFixed(2),
      items: [this.formatProduct(cartItem?.product || new Product())],
    };

    this.sendEvent('add_to_cart', params);
  }

  sendEventViewCart(cart: Cart) {
    const params = {
      currency: 'BRL',
      value: cart?.valueSubtotal?.toFixed(2),
      discount: cart?.valueDiscount?.toFixed(2),
      items: this.formatCartItem(cart?.items || []),
    };

    this.sendEvent('view_cart', params);
  }

  sendEventAddPaymentInfo(
    cartCheckout: CartCheckoutDetails,
    paymentType: string
  ) {
    const params = {
      currency: 'BRL',
      discount: cartCheckout?.discount?.toFixed(2),
      value: cartCheckout?.gross?.toFixed(2),
      item_list_id: cartCheckout?.cart?.id,
      payment_type: paymentType,
      items: this.formatCartCheckout(cartCheckout?.cart?.cartProducts || []),
    };

    this.sendEvent('add_payment_info', params);
  }

  sendEventAddShippingInfo(
    cartCheckout: CartCheckoutDetails,
    freight: FreightSelected,
    zipCode: string
  ) {
    const params = {
      currency: 'BRL',
      discount: cartCheckout?.discount?.toFixed(2),
      value: cartCheckout?.gross?.toFixed(2),
      item_list_id: cartCheckout?.cart?.id,
      shipping_tier: `Cep: ${zipCode} - Entrega: ${freight?.freight?.name} - Valor: ${freight?.freight?.priceFreight}- Dias úteis: ${freight?.freight?.deliveryTime}`,
      items: this.formatCartCheckout(cartCheckout?.cart?.cartProducts || []),
    };

    this.sendEvent('add_shipping_info', params);
  }

  sendEventViewItem(product: Product | undefined) {
    if (product) {
      const params = {
        currency: 'BRL',
        discount: product?.discount
          ? (
              (product?.value || 0) - (product?.valueWithDiscount || 0)
            )?.toFixed(2)
          : '',
        value: product?.value?.toFixed(2),
        items: [this.formatProduct(product)],
      };

      this.sendEvent('view_item', params);
    }
  }

  sendEventSearch(search: string) {
    const params = {
      search_term: search,
    };

    this.sendEvent('search', params);
  }

  sendEventBeginCheckout(order: Order) {
    const params = {
      order: {
        id: order?.id,
        status: 'PENDING',
        total_volumes: order?.totalVolumes,
        total_weight: order?.totalWeight,
        prices: {
          sub_total: (order?.gross || 0) + (order?.discount || 0),
          total_shipping: order?.freight,
          total_discount: `-${order?.discount}`,
          total: order?.totalValue,
        },

        customer: {
          id: order?.idUser,
          name: order?.nameUser,
          email: order?.emailUser,
          document: order?.documentUser,
          document_type: 'CPF',
          phone_number: order?.phoneUser,
        },

        address: {
          address: order?.address?.address,
          numberAddress: order?.address?.numberAddress,
          complement: order?.address?.complement,
          district: order?.address?.district,
          city: order?.address?.city,
          uf: order?.address?.uf,
          zipCode: order?.address?.zipCode,
        },

        shipping: {
          total: order?.orderShipping?.priceFreight,
          company: order?.orderShipping?.name,
          delivery_time: `${order?.orderShipping?.deliveryTime} dias úteis`,
          estimated: order?.orderShipping?.estimatedDelivery,
          token_offer: order?.orderShipping?.tokenOffer,
        },

        payment: {
          payment_method: order?.orderTransaction?.paymentCodeDescription,
          payment_installmentCount: order?.orderTransaction?.installmentCount
            ? `Parcelado em ${order?.orderTransaction?.installmentCount}x`
            : '',
        },
      },

      currency: 'BRL',
      discount: `-${order?.discount}`,
      value: order?.totalValue,
      items: this.formatCartItem(order?.cartProducts || []),
    };

    this.sendEvent('begin_checkout', params);
  }

  sendEventPurcharse(order: Order) {
    const params = {
      pageCategory: 'orderPlaced',
      order: {
        id: order?.id,
        status: 'PENDING',
        total_volumes: order?.totalVolumes,
        total_weight: order?.totalWeight,
        prices: {
          sub_total: (order?.gross || 0) + (order?.discount || 0),
          total_shipping: order?.freight,
          total_discount: `-${order?.discount}`,
          total: order?.totalValue,
        },

        customer: {
          id: order?.idUser,
          name: order?.nameUser,
          email: order?.emailUser,
          document: order?.documentUser,
          document_type: 'CPF',
          phone_number: order?.phoneUser,
        },

        address: {
          address: order?.address?.address,
          numberAddress: order?.address?.numberAddress,
          complement: order?.address?.complement,
          district: order?.address?.district,
          city: order?.address?.city,
          uf: order?.address?.uf,
          zipCode: order?.address?.zipCode,
        },

        shipping: {
          total: order?.orderShipping?.priceFreight,
          company: order?.orderShipping?.name,
          delivery_time: `${order?.orderShipping?.deliveryTime} dias úteis`,
          estimated: order?.orderShipping?.estimatedDelivery,
          token_offer: order?.orderShipping?.tokenOffer,
        },

        payment: {
          payment_method: order?.orderTransaction?.paymentCodeDescription,
          payment_installmentCount: order?.orderTransaction?.installmentCount
            ? `Parcelado em ${order?.orderTransaction?.installmentCount}x`
            : '',
        },
      },
      transaction_id: order?.id,
      value: order?.totalValue,
      shipping: order?.freight,
      currency: 'USD',
      coupon: order?.coupon?.code || '',
      items: this.formatCartItem(order?.cartProducts || []),
    };

    this.sendEvent('purchase', params);
  }

  sendEventWhatsApp(whatsapp: WhatsAppData) {
    const params: any = {
      name: whatsapp?.name,
      phone: whatsapp?.phone,
      address: {
        address: whatsapp?.address?.address,
        numberAddress: whatsapp?.address?.numberAddress,
        complement: whatsapp?.address?.complement,
        district: whatsapp?.address?.district,
        city: whatsapp?.address?.city,
        uf: whatsapp?.address?.uf,
        zipCode: whatsapp?.address?.zipCode,
      },
    };

    if (whatsapp?.product) {
      params.product = {
        sku: whatsapp?.product.id,
        name: whatsapp?.product.name,
        value: whatsapp?.product.value,
        discount: whatsapp?.product.discount,
        url: 'https://www.ambientabrasil.com.br/' + whatsapp?.product.url,
      };
    }

    this.sendEvent('whats_app', params);
  }

  sendEventContactForm(contactData: ContactData) {
    const params = {
      subject: contactData?.subject,
      fullName: contactData?.fullName,
      email: contactData?.email,
      phone: contactData?.phone,
      zipCode: contactData?.address?.zipCode,
      message: contactData?.message,
      change: {
        numberNFE: contactData?.change?.numberNFE,
        dtReceipt: contactData?.change?.dtReceipt,
      },
    };

    this.sendEvent('contact_form', params);
  }

  formatCartItem(items: CartItem[]) {
    return items?.map((cartItem, count = 1) => ({
      item_id: cartItem?.product?.id,
      item_name: cartItem?.product?.name,
      discount: cartItem?.product?.discount
        ? (
            (cartItem?.product?.value || 0) -
            (cartItem?.product?.valueWithDiscount || 0)
          )?.toFixed(2)
        : '',
      item_brand: cartItem?.product?.brandDescription,
      item_category: cartItem?.product?.categoryDescription,
      item_category2: cartItem?.product?.subCategoryDescription,
      index: count++,
      promotion_id:
        (cartItem?.product?.discount || 0) > 0
          ? cartItem?.product?.discount
          : '',
      promotion_name:
        (cartItem?.product?.discount || 0) > 0
          ? `Produto com ${cartItem?.product?.discount}% de desconto`
          : '',
      price: cartItem?.product?.value?.toFixed(2),
      quantity: cartItem?.product?.qtd,
    }));
  }

  formatCartCheckout(cartProducts: CartProductCheckout[]) {
    return cartProducts?.map((cartItem, count = 1) => ({
      item_id: cartItem?.product?.id,
      item_name: cartItem?.product?.name,
      discount: cartItem?.discount?.toFixed(2),
      item_brand: cartItem?.product?.brandDescription,
      item_category: cartItem?.product?.categoryDescription,
      item_category2: cartItem?.product?.subCategoryDescription,
      index: count++,
      promotion_id:
        (cartItem?.product?.discount || 0) > 0
          ? cartItem?.product?.discount
          : '',
      promotion_name:
        (cartItem?.product?.discount || 0) > 0
          ? `Produto com ${cartItem?.product?.discount}% de desconto`
          : '',
      price: cartItem?.value?.toFixed(2),
    }));
  }

  formatProduct(product: Product) {
    return {
      item_id: product?.id,
      item_name: product?.name,
      discount: product?.discount
        ? ((product?.value || 0) - (product?.valueWithDiscount || 0))?.toFixed(
            2
          )
        : '',
      item_brand: product?.brandDescription,
      item_category: product?.categoryDescription,
      item_category2: product?.subCategoryDescription,
      promotion_id: (product?.discount || 0) > 0 ? product?.discount : '',
      promotion_name:
        (product?.discount || 0) > 0
          ? `Produto com ${product?.discount}% de desconto`
          : '',
      price: product?.value?.toFixed(2),
    };
  }
}
