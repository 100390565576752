[
  {
    "id": "POLICARBONATO_ALVEOLAR",
    "informations": [
      {
        "title": "Policarbonato Alveolar",
        "contents": [
          "Chapa com aspecto similar ao <strong>vidro canelado</strong>, que possui cavidades ocas.",
          "É um material leve, e ideal para aplicações que exigem iluminação natural e para quem busca custo x benefício.",
          "Possui tratamento UV em uma das faces prolongando sua vida útil e aumentando a resistência contra o amarelamento.",
          "<a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-certificado-garantia.pdf\">Garantia da peça 10 anos, desde que seja seguido as recomendações de fábrica referente a instalação.</a>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-alveolar/001.webp"
      },
      {
        "title": "Chapas de Policarbonato Alveolar",
        "contents": [
          "As chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\"> Policarbonato Alveolar</a> são uma escolha excelente para a aplicação em <a target=\"_blank\" href=\"/kits/toldo-em-policarbonato\">toldos,</a><a target=\"_blank\" href=\"/kits/policarbonato-alveolar-kit\"> coberturas de pergolados, garagens, fechamentos e divisórias</strong>,</a> devido à sua combinação de leveza, resistência e capacidade de proporcionar iluminação natural.",
          "Sua versatilidade permite que sejam utilizadas tanto em <strong>ambientes comerciais</strong> quanto <strong>residenciais</strong>, adaptando-se às necessidades específicas de cada projeto. Seja para criar espaços mais luminosos e aconchegantes em uma casa, ou para oferecer proteção e conforto em estabelecimentos comerciais, essas chapas oferecem uma solução confiável e eficaz",
          "Além disso, sua durabilidade e resistência aos efeitos do tempo e das condições climáticas garantem que as instalações permaneçam seguras e funcionais ao longo do tempo, proporcionando tranquilidade aos proprietários e usuários desses espaços",
          "Em resumo, as chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\"> Policarbonato Alveolar</a> são uma escolha inteligente e versátil para uma ampla gama de aplicações, contribuindo para a criação de ambientes mais agradáveis e funcionais em diversos tipos de construções e projetos."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-alveolar/002.webp"
      },
      {
        "title": "Cores das Chapas de Policarbonato Alveolar",
        "contents": [
          "Indicado para projetos que exigem iluminação natural, o nivel de transparência do <strong>Policarbonato Alveolar</ strong> chega a transparência chega a 80%.",
          "Para ambientes onde a preocupação com o excesso de calor é um fator decisivo, sugerimos a aplicação das <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\"> Chapas de Policarbonato Alveolar</a><strong> Refletivas</strong>, pois são fabricadas com pigmento especial que auxiliam na redução da transmissão dos raios infra-vermelhos responsáveis por gerar calor.",
          "<strong>Cores das Chapas Alveolares Refletivas:</strong> Cinza Refletivo - 15% de transmissão de luz - bloqueia de 4º a 5ºC Branco Pérola Refletivo - 20% de transmissão de luz - bloqueia de 3º a 4ºC",
          "<strong>Cores das Chapas Alveolares Simples:</strong> Cristal: 80% de transmissão de luz | Verde: 62% de transmissão de luz | Branco Opal: 40% de transmissão de luz Bronze: 35% de transmissão de luz | Azul: 27% de transmissão de luz | Fume: 20% de transmissão de luz."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-alveolar/003.webp"
      },
      {
        "title": "Qual a espessura da Chapa de Policarbonato Alveolar",
        "contents": [
          "Para escolher a espessura correta da chapa de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\"> Policarbonato Alveolar</a> é necessário levar em consideração fatores como:",
          "<strong>Tipo de aplicação:</strong> cobertura ou fechamento;",
          "<strong>Tamanho:</strong> quanto maior área a ser coberta é recomendado que utilize chapas mais grossas;",
          "<strong>Estrutura:</strong> a espessura vai variar de acordo com a quantidade de caibros/travessas da sua estrutura e o vão livre;",
          "É extremamente importante seguir as recomendações de espaçamento para que as chapas <strong>tenham a garantia de 10 anos</strong>, e para que <strong>não ocorra infiltração de água</strong> ou que forme 'barriga'",
          "<strong>Policarbonato Alveolar 4mm:</strong> até 50cm de vão entre os caibros;",
          "<strong>Policarbonato Alveolar 6mm:</strong> até 70cm de vão entre os caibros;",
          "<strong>Policarbonato Alveolar 10mm:</strong> até 1m de vão entre os caibros;",
          "As recomendações de vão livre podem várias de acordo com fabricante da chapa, isso porque é um calculo feito de acordo com o peso da chapa. Quanto mais leve, mais fina será a chapa, e menor o vão entre os caibros."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-alveolar/004.webp"
      },
      {
        "title": "Instalação do Policarbonato Alveolar",
        "contents": [
          "As <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\"> Chapas de Policarbonato Alveolar</a> possuem a medida padrão de 2,10m de largura x 6,00m de avanço, sendo o sentido do alvéolos para o lado dos 6,00m de avanço.",
          "<strong>1 -</strong> O sentido dos alvéolos deve ser o mesmo dos caibros da estrutura;",
          "<strong>2 -</strong> Utilize as fitas vedação <a target=\"_blank\" href=\"/acessorios-de-instalacao/fitas-selantes/355/fita-adesiva-aluminio-25mm-x-30m\">Fita Adesiva Aluminio</a> e <a target=\"_blank\" href=\"/acessorios-de-instalacao/fitas-selantes/354/fita-adesiva-porosa-25mm-x-50m\">Fita Adesiva Porosa</a>, para a vedação correta dos alvéolo, evitando as infiltrações e o aspecto sujo;",
          "<strong>3 -<a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\"> As Chapas de Policarbonato Alveolar</a> não devem ser fixadas direto no parafuso,</strong> pois dilatam com o calor e acabam sendo arrancadas pelo vento, além de que dessa forma não tem vedação;",
          "<strong>4 -</strong> Para emendas e fixações, utilize o <a target=\"_blank\" href=\"/perfis-em-aluminio/perfis-fixacao-acabamento\">Perfil Trapézio</a> + <a target=\"_blank\" href=\"/acessorios-de-instalacao/fitas-selantes\">Gaxeta</a> e o <a target=\"_blank\" href=\"/perfis-em-aluminio/perfis-fixacao-acabamento\">Perfil U Pingadeira</a> para acabamento e proteção das fitas de vedação;",
          "<strong>5 -</strong> Verifique as instruções de instalação preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\"> clicando aqui </a>, ou assista o vídeo de instalação <a target=\"_blank\" href=\"https://youtu.be/hJfnH5HLzKM\"> clicando aqui </a>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-alveolar/005.webp"
      },
      {
        "title": "Garantia do Policarbonato Alveolar",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, nós trabalhamos com chapas de policarbonato fabricadas com matéria prima 100% virgem e tratamento UV.",
          "<strong>Todas as chapas possuem 10 anos de garantia contra amarelamento e ressecamento.</strong>",
          "Para que as chapas tenham garantia é importante seguir o passo a passo de instalação corretamente, assim como em caso de abertura de garantia enviar a amostra do material utilizado para análise.",
          "Você pode ver mais sobre a garantia e como aciona-la <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-certificado-garantia.pdf\"> clicando aqui.</a>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-alveolar/006.webp"
      },
      {
        "title": "Materiais para instalação do Policarbonato Alveolar",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, você encontra todos os materiais necessários para a instalação das <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\"> Chapas de Policarbonato Alveolar</a>. Fornecemos instruções de instalação e suporte técnico para que você consiga realizar o seu projeto!",
          "Entre em contato com os nossos consultores <a target=\"_blank\" href=\"/fale-conosco\"> clicando aqui</a>, e tire todas as suas duvidas para efetuar uma compra segura.",
          "Enviamos material para todo o Brasil, sendo que algumas regiões possuem restrições de medida para envio, solicite o corte do material para que possa recebe-lo. "
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-alveolar/007.webp"
      }
    ]
  },
  {
    "id": "POLICARBONATO_COMPACTO",
    "informations": [
      {
        "title": "Policarbonato Compacto",
        "contents": [
          "Chapa sólida e de alta qualidade, possui aspecto de vidro, e ótima transmissão de luz. Ideal para substituição do vidro e acrílico, aplicação em grandes obras e enclausuramento de máquinas. Possui tratamento UV prolongando sua vida útil e aumentando a resistência contra o amarelamento.",
          "<strong>Garantia da peça 10 anos, desde que seja seguido as recomendações de fábrica referente a instalação.</strong>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-compacto/001.webp"
      },
      {
        "title": "Chapas de Policarbonato Compacto",
        "contents": [
          "As chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\"> Policarbonato Compacto</a> são uma escolha excelente para aplicação em grandes obras, ou substituição de vidro e acrílico. Porém, podem ser aplicadas em <a target=\"_blank\" href=\"/kits/policarbonato-compacto-kit\"> coberturas de pergolados</a>, garagens, fechamentos, divisórias, e enclausuramento de máquinas.",
          "Sua versatilidade permite que sejam utilizadas tanto em <strong>ambientes comerciais</strong> quanto <strong>residenciais</strong>, adaptando-se às necessidades específicas de cada projeto. Essas chapas oferecem uma solução confiável e eficaz, sendo até 250x vezes mais resistentes que o vidro comum e 40x mais resistentes que o acrilico.",
          "Além disso, sua durabilidade e resistência aos efeitos do tempo e das condições climáticas garantem que as instalações permaneçam seguras e funcionais ao longo dos anos, proporcionando tranquilidade aos proprietários e usuários desses espaços.",
          "Em resumo, as chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\"> Policarbonato Compacto</a> são uma escolha inteligente e versátil para uma ampla gama de aplicações, contribuindo para a criação de ambientes mais agradáveis e funcionais em diversos tipos de construções e projetos."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-compacto/002.webp"
      },
      {
        "title": "Cores das Chapas de Policarbonato Compacto",
        "contents": [
          "Indicado para projetos que exigem iluminação natural, o nível de transparência do <a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Cristal%203mm\"> Policarbonato Compacto chega à transparência chega a 88% na cor cristal.</a> ",
          "Atenção: todas as chapas possuem tratamento UV, isso está ligado a durabilidade e a capacidade de ficar exposto ao tempo sem danificar, não possui controle de temperatura como as Chapas Alveolares Refletivas.",
          "Cores das <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\"> Chapas de Policarbonato Compacto:</a>",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Cristal\">Cristal: 88% de transmissão de luz.</a>",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Verde\">Verde: 78% de transmissão de luz.</a>",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Branco\">Branco Opal: 29% de transmissão de luz.</a>",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Bronze\">Bronze: 52% de transmissão de luz.</a>",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Azul\">Azul: 27% de transmissão de luz.</a>",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Fume\">Fume: 22% de transmissão de luz.</a>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-compacto/003.webp"
      },
      {
        "title": "Qual a Espessura da Chapa de Policarbonato Compacto ",
        "contents": [
          "Para escolher a espessura correta da <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\"> Chapa de Policarbonato Compacto</a> é necessário levar em consideração fatores como:",
          "<strong>Tipo de aplicação:</strong> cobertura ou fechamento;",
          "<strong>Tamanho:</strong> quanto maior área a ser coberta é recomendado que utilize chapas mais grossas;",
          "<strong>Estrutura:</strong> a espessura vai variar de acordo com a quantidade de caibros/travessas da sua estrutura e o vão livre;",
          "É extremamente importante seguir as recomendações de espaçamento para que as chapas <strong>tenham a garantia de 10 anos,</strong> e para que <strong>não ocorra infiltração de água</strong> ou que forme 'barriga' ",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Cristal%203mm\"> Policarbonato Compacto 3mm:</a> até 50cm de vão entre os caibros;",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Cristal%204mm\"> Policarbonato Compacto 4mm:</a> até 60cm de vão entre os caibros;",
          "<a target=\"_blank\" href=\"/produtos/Chapa%20De%20Policarbonato%20Compacto%20Cristal%206mm\"> Policarbonato Compacto 6mm:</a> até 1m de vão entre os caibros;",
          "Demais espessuras, 8mm, 9mm, 10mm e 12mm: de acordo com o projeto de segurança.",
          "As recomendações de vão livre podem várias de acordo com fabricante da chapa, isso porque é um cálculo feito de acordo com o peso da chapa. Quanto mais leve, mais fina será a chapa, e menor o vão entre os caibros."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-compacto/004.webp"
      },
      {
        "title": "Instalação da Chapa de Policarbonato Compacto",
        "contents": [
          "As Chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\"> Policarbonato Compacto</a> possuem a medida padrão de 2,00m de largura x 6,00m de avanço.",
          "1 - <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\"> As Chapas de Policarbonato Compacto</a> <strong>não devem ser perfuradas ou fixadas direto no parafuso,</strong> pois dilatam com o calor e acabam sendo arrancadas pelo vento, além de que dessa forma não oferece vedação;",
          "2 - Para emendas e fixações, utilize o Perfil Trapézio + Gaxeta e o Perfil U pingadeira para acabamento.",
          "3 - Verifique as instruções de instalação preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\"> clicando aqui</a>, ou assista o vídeo de instalação <a target=\"_blank\" href=\"https://www.youtube.com/watch?v=hJfnH5HLzKM\"> clicando aqui</a>."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-compacto/005.webp"
      },
      {
        "title": "Garantia do Policarbonato Compacto",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, nós trabalhamos com chapas de policarbonato fabricadas com matéria prima 100% virgem e tratamento UV.",
          "<strong>Todas as chapas possuem 10 anos de garantia contra amarelamento e ressecamento.</strong>",
          "Para que as chapas tenham garantia é importante seguir o passo a passo de instalação corretamente, assim como em caso de abertura de garantia enviar a amostra do material utilizado para análise.",
          "Você pode ver mais sobre a garantia e como aciona-la <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-certificado-garantia.pdf\">clicando aqui.</a>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-compacto/006.webp"
      },
      {
        "title": "Materiais para instalação do Policarbonato Compacto ",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, você encontra todos os materiais necessários para a instalação das Chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\"> Policarbonato Compacto</a>. Fornecemos instruções de instalação e suporte técnico para que você consiga realizar o seu projeto!",
          "Entre em contato com os nossos consultores <a target=\"_blank\" href=\"/fale-conosco\">clicando aqui</a>, e tire todas as suas dúvidas para efetuar uma compra segura.",
          "Enviamos material para todo o Brasil, sendo que algumas regiões possuem restrições de medida para envio, solicite o corte do material para que possa recebe-lo."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/policarbonato-compacto/007.webp"
      }
    ]
  },
  {
    "id": "ROLO",
    "informations": [
      {
        "title": "Persiana Rolo",
        "contents": [
          "<a target=\"_blank\" href=\"/persianas/rolo\">Persiana ou Cortina em Rolo</a> são tipos de cortinas que se movem para cima e para baixo. Elas podem ser colocadas na parede, na janela ou no teto.",
          "Apesar de serem uma opção mais econômica, elas conseguem bloquear bem a luz nos<a target=\"_blank\" href=\"/persianas/rolo\"> tecidos blackout,</a> e também e boa filtragem de raios UV nos tecidos<a target=\"_blank\" href=\"/persianas/rolo\"> Screen</a>. Isso faz com que sejam ótima escolha para decorar e dar uma cara nova para os ambientes!"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/001.webp"
      },
      {
        "title": "Cortinas Persianas Rolo",
        "contents": [
          "As<a target=\"_blank\" href=\"/persianas/rolo\"> Persianas Rolo</a>, também conhecidas como <a target=\"_blank\" href=\"/persianas/rolo\">Cortinas Persianas Rolo</a>, são uma escolha excepcional para a decoração dos seus espaços. Com um design contemporâneo e um sistema mecânico de alta precisão, elas unem a estética refinada das cortinas de alta qualidade com a praticidade e funcionalidade das persianas.",
          "Este modelo de persiana, devido à sua versatilidade e eficiência, é amplamente recomendado por arquitetos designers de interiores renomados. É especialmente apreciado por aqueles que buscam equilibrar a estética do ambiente com a praticidade no dia a dia.",
          "Lembre-se de que as <a target=\"_blank\" href=\"/persianas/rolo\">Persianas Rolo</a> são altamente personalizáveis, oferecendo uma ampla gama de tecidos, cores e texturas para atender às necessidades e preferências específicas de cada ambiente.",
          "Portanto, ao escolher as <a target=\"_blank\" href=\"/persianas/rolo\">Persianas Rolo</a>, você está investindo não apenas em beleza, mas também em funcionalidade e praticidade para seus espaços.",
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> temos a <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Screen%203%25\">Persiana Rolo Screen</a>, <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Pinpoint\">Persiana Rolo Blackout Pinpoint</a> e <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout\">Persiana Rolo Blackout Decorativo</a>."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/002.webp"
      },
      {
        "title": "Persiana Rolo Tela Solar Screen 3%",
        "contents": [
          "A Persiana Rolo Tela Solar, aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> é fabricada com tecido Screen 3%, este modelo é uma escolha ideal para ambientes sujeitos a altas exposições solares, pois <strong>reduz a entrada de luz solar</strong>, proporcionando um controle eficaz da temperatura, sem comprometer a visão externa. <strong>Durante o dia é possível visualizar</strong> o exterior, e a noite isso se inverte.",
          "Este produto, conhecido por sua qualidade excepcional e longa durabilidade, possui uma base de alumínio, de acordo com a cor do tecido, com revestimento eletrostático.",
          "Além de sua capacidade de diminuir o calor, o tecido desta persiana <strong>retém 95% dos raios UVA e UVB</strong>, aqueles que podem causar danos à pele. Por refletir tanto a luz quanto o calor externo, também proporciona <strong>maior economia de energia para quem faz uso do ar condicionado.</strong>",
          "O design moderno e ousado da <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Screen%203%25\">Persiana Rolo Tela Solar Screen 3%</a> complementa perfeitamente composições minimalistas, elevando o conforto e estilo do ambiente.",
          "Escolha esta opção para desfrutar de um espaço mais agradável e protegido dos efeitos nocivos do sol."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/003.webp"
      },
      {
        "title": "Persiana Rolo Blackout Pinpoint e Blackout Decorativo",
        "contents": [
          "As <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout\">Persianas Rolo Blackout</a> estão disponíveis em dois tipos de modelo aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>",
          "<a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Pinpoint\">1 - Persiana Rolo Blackout Pinpoint:</a> tem o aspecto liso, não tem poros, é um material com altíssima resistência, é antialérgico, antichamas, de fácil limpeza não descasca e não perde a cor. O tecido tem composição de fibra de vidro envolta em PVC. Sua instalação é <strong>indicada em espaços úmidos</strong>, como banheiros, cozinhas,mas a persiana rolo certamente transformará com estilo qualquer  espaço em que for instalada. ",
          "<a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout\">2 - Persiana Rolo Blackout Napolis:</a> tem expecto <strong>texturizado e aveludado na frente</strong> e o verso branco. Seu tecido possui composição de poliéster e PVC. Sua instalação é <strong>indicada para locais secos</strong> como quartos,escritórios e salas. Mas se adapta muito bem a vários tipos de decoração e ambientes. ",
          "<strong>Ambos bloqueiam 100% da passagem de luz</strong> deixando os ambientes protegidos e aconchegantes, proporcionando melhor qualidade no sono e maior produtividade e possuem diversas cores e tamanhos.",
          "<strong>Dica:</strong> Se o seu objetivo é melhorar a qualidade do seu sono e aumentar a sua produtividade nós temos a versão da <strong>Persiana Kitbox (ou caixa box).</strong>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/004.webp"
      },
      {
        "title": "Persiana Rolo Translúcida",
        "contents": [
          "A <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Translucida\">Persiana Rolo Translúcida</a> oferece uma solução elegante para quem busca equilibrar <strong>privacidade e luminosidade</strong>. Seu tecido permite a entrada de luz difusa, proporcionando um ambiente claro e agradável, ao mesmo tempo em que <strong>preserva a discrição ao bloquear a visão externa.</strong> ",
          "O tecido apresenta uma textura minimalista, conferindo um toque delicado e charmoso à decoração do espaço onde é instalada. Essa característica transforma a atmosfera do ambiente, agregando um visual moderno e sofisticado. ",
          "Nós da <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> indicamos esta persiana é especialmente para <strong>ambientes secos, onde há necessidade de iluminação</strong> como salas, corredores, espaços corporativos e escritórios. ",
          "Ao ter o controle sobre a entrada de luz, é possível criar diferentes cenários e jogos de profundidade luminosa, adaptando o ambiente de acordo com as necessidades e preferências.  ",
          "Além disso, a <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Translucida\">Persiana Rolo Translúcida</a> contribui para a economia, reduzindo a necessidade de iluminação durante o dia. Isso não só beneficia o meio ambiente, mas também resulta em economia financeira para o usuário."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/005.webp"
      },
      {
        "title": "Quais os tipos de Acionamento da Persiana Rolo",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> temos duas opções de acionamento para a <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Screen%203%25\">Persiana Rolo Screen 3%, </a> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Pinpoint\">Rolo Blackout Pinpoint, </a> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout\">Rolo Decorativo</a> e <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Translucida\">Rolo Translúcido</a>",
          "Os anúncios que irá encontrar no site são com <strong>acionamento manual</strong> que possui uma corrente (ou cordão) que, quando acionado, recolhe os tecidos para cima, envolvendo-os em um tubo de alumínio. O acionamento motorizado é uma opção que pode ser adquirida a parte, no momento da compra.",
          "Confira todas as opções abaixo:",
          "<strong>1 - Acionamento manual:</strong> o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça.",
          "<strong>2 - Acionamento motorizado:</strong> permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/006.webp"
      },
      {
        "title": "Persiana Rolo para Sala",
        "contents": [
          "<a target=\"_blank\" href=\"/persianas/rolo\">As Persianas Rolo para Sala</a> proporcionam um toque refinado, realçando a beleza do ambiente enquanto controlam a entrada de luz e temperatura. Isso resulta em maior conforto e economia para o espaço.",
          "Veja os modelos que nós aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> indicamos:",
          "<strong>1 - Salas de Televisão ou Projeção com alta intensidade de luz:</strong> Persiana Rolo Blackout Decorativo ou Persiana Rolo Blackout Pinpoint.",
          "<strong>2 - Salas onde se deseja a presença de luz, controle de calor e manter a visão externa:</strong> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Screen%203%25\">Persiana Rolo Screen 3%</a>.",
          "<strong>3 - Salas que precisam de luz difusa e privacidade, sem visão externa:</strong> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Translucida\">Persiana Rolo Translúcida</a>.",
          "Em todos os modelos temos disponível várias opções de cores e tecidos. As peças são fabricadas sob medida, exclusivamente para cada projeto."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/007.webp"
      },
      {
        "title": "Persiana Rolo para o Quarto",
        "contents": [
          "<a target=\"_blank\" href=\"/persianas/rolo\">As Persianas Rolo Para Quarto</a>, proporcionam ambientes protegidos e aconchegantes, <strong>pois bloqueiam 100% da passagem de luz</strong> garantindo qualidade de sono e produtividade.",
          "Os modelos que indicamos aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> são a <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout\">Persiana Rolo Blackout Decorativo</a> ou <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Pinpoint\">Persiana Rolo Blackout Pinpoint</a>, pois ambos irão vedar a luz, sendo o modelo de escolhido de acordo com o gosto e estilo do cliente. No entanto é preciso de atentar á forma de instalação.",
          "<strong>1 - Instalação de Persiana Rolo Fora do vão:</strong> na instalação fora do vão é necessário deixar 15cm a 20cm mais que a medida da janela (para cada lado). Isso fará com que o bloqueio de luz seja mais eficiente.",
          "<strong>2 - Instalação de Persiana Rolo Dentro do Vão:</strong> para uma vedação de 100% da luz na instalação dentro do",
          "Em todos os modelos temos disponível várias opções de cores e tecidos. As peças são fabricadas sob medida, exclusivamente para cada projeto."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/008.webp"
      },
      {
        "title": "Persiana Rolo para o Escritório",
        "contents": [
          "As <a target=\"_blank\" href=\"/persianas/rolo\">Persianas Rolo para o Escritório</a> proporcionam um toque refinado, realçando a beleza do ambiente enquanto controlam a entrada de luz e temperatura. Isso resulta em maior conforto e economia  para o espaço.",
          "Veja os modelos que nós aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> indicamos:",
          "<strong>1 - Ambientes com alta intensidade de luz:</strong> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout\">Persiana Rolo Blackout Decorativo</a> ou <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Pinpoint\">Persiana Rolo Blackout Pinpoint</a>.",
          "<strong>2 - Onde se deseja a presença de luz, controle de calor e manter a visão externa:</strong> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Screen%203%25\">Persiana Rolo Screen 3%</a>.",
          "<strong>3 - Onde precisam de luz difusa e privacidade, sem visão externa:</strong> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Translucida\">Persiana Translúcida</a>.",
          "Em todos os modelos temos disponível várias opções de cores e tecidos. As peças são fabricadas sob medida, exclusivamente para cada projeto."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/009.webp"
      },
      {
        "title": "Persiana Rolo para Cozinha",
        "contents": [
          "Para a cozinha, nós da <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> sempre recomendamos o uso de <a target=\"_blank\" href=\"/persianas/rolo\">Persianas Rolo</a> que sejam práticas, fáceis de limpar e que proporcionem uma boa gestão da luz.",
          "<strong>1 - Excelente controle de calor e permite visão externa:</strong> Persiana Rolo Tela solar Screen 3%, retém 95% dos raios UVA e UVB, que reduz o calor e a luz solar sem perder a visão externa.",
          "<strong>2 - Controle de luz e extrema facilidade de limpeza:</strong> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Pinpoint\">Persiana Rolo Blackout Pinpoint</a>, bloqueia 100% a luz solar, o seu tecido tem o aspecto liso, não tem poros, é um material com altíssima resistência, é antialérgico, antichamas, de fácil limpeza não descasca e não perde a cor.",
          "Em todos os modelos temos disponível várias opções de cores e medidas. As peças são fabricadas sob medida, exclusivamente para cada projeto."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/010.webp"
      },
      {
        "title": "Persiana Rolo para o Banheiro",
        "contents": [
          "<a target=\"_blank\" href=\"/persianas/rolo\">As Persianas Rolo</a> são práticas e versáteis. Como utilizam materiais sintéticos de primeira qualidade em sua confecção, possuem modelos que podem ser utilizadas em áreas úmidas, como banheiros, áreas de serviço e lavanderia, desde que não tenham contato direto com a água.",
          "Veja os modelos que nós aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> indicamos:",
          "<strong>1 - Contato Direto com água:</strong> <a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Screen%203%25\">Persiana Rolo Screen 3%</a>, mas fique atento que este modelo permite visão externa. <strong>Durante o dia é possível visualizar</strong> o exterior, e a noite isso se inverte.",
          "<strong>2 - Área molhada, sem contato direto com água:</strong> Persiana Rolo Blackout Pinpoint, tem o aspecto liso, não tem poros, é um material com altíssima resistência, é antialérgico, antichamas, de fácil limpeza não descasca e não perde a cor. O tecido tem composição de fibra de vidro envolta em PVC ",
          "Em todos os modelos temos disponível várias opções de cores e tecidos. As peças são fabricadas sob medida, exclusivamente para cada projeto."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/011.webp"
      },
      {
        "title": "Acessórios para Persiana Rolo",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, você encontra todos diversos acessórios e para <a target=\"_blank\" href=\"/persianas/rolo\">Persianas Rolo</a>, como <a target=\"_blank\" href=\"/persianas/acessorios-persianas\">Bandô de Acabamento, Barra Niveladora para instalação em gesso e Motor para persianas.</a>",
          "Entre em contato com os nossos consultores <a target=\"_blank\" href=\"/fale-conosco\"> clicando aqui</a>, e tire todas as suas duvidas para efetuar uma compra segura.",
          "Enviamos material para todo o Brasil, sendo que algumas regiões possuem restrições de medida para envio, solicite o apoio do nosso time."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-rolo/012.webp"
      }
    ]
  },
  {
    "id": "DOUBLE_VISION",
    "informations": [
      {
        "title": "Cortina Persiana Double Vision",
        "contents": [
          "Modelo sofisticado e variado, com opções de cores e tramas para todos os gostos. Double Vision é uma persiana que sobe e desce enrolando e consiste em duas telas de tecido dispostas no mesmo mecanismo.",
          "Dependendo do movimento do controle na persiana, você pode escolher a maneira ideal de deixar a luz entrar, podendo escurecer levemente o ambiente, ou deixar mais claro e manter a privacidade."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/001.webp"
      },
      {
        "title": "Cortina Double Vision",
        "contents": [
          "A <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Rolo Double Vision</a> como o próprio nome mesmo já indica, essa é uma peça que permite uma espécie de dupla visão, sendo um modelo bastante moderno e ainda muito sofisticado e que cada vez mais tem sido escolhido para agregar ainda mais beleza na decoração.",
          "Este modelo de persiana não é apenas uma persiana extremamente bonita, como também versátil e  funcional, mas permite um design realmente único, que torna possível uma linha visual mais atual e interessante. ",
          "Com tecido em poliéster, o modelo translúcido permite criar diferentes cenas, “brincando” com a entrada de luz entre as faixas, transformando qualquer ambiente com requinte e personalidade. ",
          "A <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a> permite que seja aproveitado melhor a luz externa, deixando a entrega no cômodo quando necessário, por isso seu modelo pode ser aplicado nos mais diversos ambientes.",
          "<strong>Dica:</strong> pelas frestas existentes na <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a> você poderá contemplar a paisagem externa na sua janela sem que seja preciso sequer abrir a cortina!",
          "Esse modelo tem sido uma escolha, principalmente se tratando de ambientes corporativos ou até mesmo  quem vive em apartamentos e querem mais privacidade."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/002.webp"
      },
      {
        "title": "Tipo de Tecido e Cores para a Persiana Double Vision",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> trabalhamos com tecidos duplos 100% poliéster. Este tipo de tecido possui um acabamento semi translúcido que não deixa uma escuridão no ambiente e permite regular a passagem de luz através da sobreposição dos tecidos, podendo escolher entre abertura total ou privacidade.",
          "Os tecidos são Anti-Chama, Anti-Alérgico, Anti-Ácaro, e retém 95% dos raios UVA e UVB, reduzindo o calor do ambiente.",
          "As cores dispoíiveis para o tecido da <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a> são: Branco | Bege Mescla/ Linho | Cinza |Preto.",
          "Componentes e acessórios que compõe a persiana são de alta performance e excelente qualidade e vida útil. Tanto a base superior, como a base inferior são em aluminio com pintura eletrostática.",
          "Ao optar por tecidos em cores clara, os acabamento serão enviados da cor branca. <strong>Se a escolha do tecido for na cor preta, os acabamentos irão em preto para combinar!</strong>"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/003.webp"
      },
      {
        "title": "Quais as vantagens da Persiana Double Vision",
        "contents": [
          "A <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a>, também conhecida como persiana rolô dupla, possui diversas vantagens que a tornam uma escolha popular para muitos ambientes.",
          "Abaixo estão algumas das principais vantagens:",
          "<strong>1 - Ótimo controle de luz com movimento das faixas;</strong>",
          "<strong>2 - Rápido de instalar, fácil de usar e manter:</strong> todos os envios acompanham manual de instalação;",
          "<strong>3 - Visual moderno e aconchegante:</strong> combinam com qualquer estilo de decoração;",
          "<strong>4 - Abertura total se necessário:</strong> podem ser completamente abertas e escondidas, deixando toda a janela livre, o que facilita a limpeza das janelas;",
          "<strong>5 - Não ficam batendo:</strong> quando estão enroladas e as janelas abertas, não balançam nem esvoaçam ao vento como a maioria das cortinas;",
          "<strong>6 - Fácil Manutenção:</strong> pois geralmente basta a limpeza com aspirador e um pano úmido para remover a sujeira."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/004.webp"
      },
      {
        "title": "Quais as vantagens da Persiana Double Vision",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a> temos duas opções de acionamento para a <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Rolo Double Vision</a>.",
          "Os anúncio que irá encontrar no site são com <strong>acionamento manual</strong> que possui uma corrente (ou cordão) que, quando acionado, recolhe os tecidos para cima, envolvendo-os em um tubo de alumínio.",
          "O <strong>acionamento motorizado</strong> é uma opção que pode ser adquirida a parte, no momento da compra. Confira todas as opções abaixo:",
          "<strong>1 - Acionamento manual:</strong> o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça.",
          "<strong>2 - Acionamento motorizado:</strong> permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/005.webp"
      },
      {
        "title": "Persiana Double Vision para o Escritório",
        "contents": [
          "A <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a> é a escolha ideal para o seu escritório, devido ao seu total controle sobre a entrada de luz e a privacidade, proporcionando um ambiente de trabalho mais confortável e produtivo.",
          "Além da funcionalidade, a facilidade de instalação, a praticidade na manutenção e o visual moderno são  características que fazem dessa persiana uma opção excelente para qualquer ambiente de escritório.",
          "Na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, oferecemos uma ampla variedade de tecidos e cores para atender às suas preferências e necessidades específicas.",
          "Seu tecido, muito leve, moderno e versátil, garante um acabamento aconchegante e refinado. Indicado para consultórios, escritórios, estabelecimentos comerciais e janelas em geral.",
          "Escolha a <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a> e eleve o estilo e o conforto do seu escritório para um novo patamar.",
          "Entre em contato com os nossos consultores <a target=\"_blank\" href=\"/fale-conosco\"> clicando aqui</a>, e tire todas as suas duvidas para efetuar uma  compra segura!"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/006.webp"
      },
      {
        "title": "Persiana Double Vision para Sala",
        "contents": [
          "Se você está buscando uma persiana para a sala saiba que a <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a>, fará o escurecimento <strong>parcial do ambiente</strong>, tornando o seu espaço amplamente utilizável sem a escuridão total.",
          "As faixas de tecido opaco tem uma camada emborrachada que bloqueia a luz, e a tela da camada translúcida proporciona uma luz difusa, o ambiente fica perfeito para o uso da TV, ou mesmo um momento de relaxamento.",
          "Se optar pela luz total utilizando as telas, não perderá a vista da sua janela, mas terá privacidade e bloqueio do calor.",
          "Além da funcionalidade, a facilidade de instalação, a praticidade na manutenção e o visual moderno são  características que fazem dessa persiana uma opção excelente.",
          "Na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, oferecemos uma ampla variedade de tecidos e cores para atender às suas preferências e necessidades específicas.",
          "Escolha a <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a>, entre em contato com os nossos consultores <a target=\"_blank\" href=\"/fale-conosco\"> clicando aqui</a>, e tire todas as suas duvidas para efetuar uma compra segura!"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/007.webp"
      },
      {
        "title": "Persiana Double Vision para o Quarto",
        "contents": [
          "Entre as muitas vantagens do modelo da <a target=\"_blank\" href=\"/persianas/double-vision\">Cortina Persiana Double Vision</a> para quarto está a facilidade de combinar com qualquer estilo de decoração. Se você não abre mão de uma persiana que combina aspectos funcionais e estéticos, uma Double Vision será útil.",
          "A <a target=\"_blank\" href=\"/persianas/double-vision\">Cortina Persiana Double Vision</a> para quarto irá mantê-lo aconchegante, confortável e protegido. Escolha a melhor opção e mude o seu quarto agora mesmo! O tecido leve, moderno e versátil proporciona um acabamento aconchegante e refinado, <strong>transformando o quarto em um ambiente acolhedor</strong> e propício ao relaxamento.",
          "Na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, oferecemos uma ampla variedade de tecidos e cores para atender às suas preferências e necessidades específicas.",
          "Escolha a <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a> entre em contato com os nossos consultores <a target=\"_blank\" href=\"/fale-conosco\"> clicando aqui</a>, e tire todas as suas duvidas para efetuar uma compra segura!"
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/008.webp"
      },
      {
        "title": "Acessórios para Persiana Double Vison",
        "contents": [
          "Aqui na <a target=\"_blank\" href=\"/\">Ambientá Brasil</a>, você encontra todos diversos acessórios e para Persianas Double Vision, como <a target=\"_blank\" href=\"/persianas/acessorios-persianas\">Bandô de Acabamento, Barra Niveladora para instalação em gesso e Motor para persianas.</a>",
          "Entre em contato com os nossos consultores <a target=\"_blank\" href=\"/fale-conosco\"> clicando aqui</a>, e tire todas as suas duvidas para efetuar uma compra segura.",
          "Enviamos material para todo o Brasil, sendo que algumas regiões possuem restrições de medida para envio, solicite o apoio do nosso time."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-double-vision/009.webp"
      }
    ]
  },
  {
    "id": "ROMANA",
    "informations": [
      {
        "title": "Persiana Romana",
        "contents": [
          "Descubra a elegância das <a target=\"_blank\" href=\"/persianas/romana\">Persianas Romanas</a>. Com gomos largos e horizontais, essas persianas se dobram suavemente ao serem abertas ou fechadas. Práticas e simples de instalar e limpar, oferecem um acabamento reforçado e recolhimento sofisticado.",
          "Escolha entre tecido <a target=\"_blank\" href=\"/produtos/romana%20blackout\">Blackout</a> e <a target=\"_blank\" href=\"/produtos/romana%20translucida\">Translúcido</a> para atender às suas necessidades."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-romana/001.webp"
      },
      {
        "title": "Persiana Romana para Sala",
        "contents": [
          "Considere as <a target=\"_blank\" href=\"/produtos/romana%20blackout\">Cortinas Romanas Blackout</a> para a sua sala como uma alternativa inteligente! Encontre opções sob medida, garantindo que o produto atenda às suas necessidades e expectativas de decoração. As Cortinas Romanas oferecem uma regulagem precisa de altura, proporcionando controle de luminosidade e aumentando o conforto na sala. Explore a <a target=\"_blank\" href=\"/persianas/romana\">Persianas Romanas</a> com tecido translucido uma excelente opção. Recomendamos também a elegante <a target=\"_blank\" href=\"/persianas/double-vision\">Persiana Double Vision</a>, que adiciona beleza a qualquer ambiente."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-romana/002.webp"
      },
      {
        "title": "Persiana Romana para Quarto",
        "contents": [
          "Seu quarto merece uma atmosfera de relaxamento absoluto, e a escolha da persiana certa desempenha um papel crucial nesse cenário. A <a target=\"_blank\" href=\"/persianas/romana\">Persianas Romanas</a> é a opção ideal, especialmente quando equipada com a funcionalidade <a target=\"_blank\" href=\"/produtos/romana%20blackout\">Blackout</a>. Ao optar por esse recurso, você obtém o controle total, bloqueando efetivamente 100% da iluminação e criando um ambiente propício ao descanso.",
          "Não subestime a importância de uma <a target=\"_blank\" href=\"/persianas/romana\">Persianas Romanas para o quarto</a>, oferecendo não apenas apelo estético, mas também a capacidade de transformar seu espaço em um refúgio tranquilo. Essa escolha cuidadosa proporciona proteção contra a luz excessiva e possíveis perturbações sonoras, garantindo um ambiente perfeito para o descanso.",
          "Invista no conforto e estilo que uma <a target=\"_blank\" href=\"/persianas/romana\">Persianas Romanas</a> pode proporcionar ao seu quarto, elevando a experiência de descanso a um novo patamar."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-romana/003.webp"
      },
      {
        "title": "Cortina Romana Blackout ",
        "contents": [
          "A<a target=\"_blank\" href=\"/produtos/romana%20blackout\"> Persiana Blackout Napolis</a> apresenta um tecido decorativo que combina elegância e funcionalidade ao bloquear a luminosidade, conferindo um aspecto moderno e especial à decoração. Com sua composição de PVC e Poliéster, garante durabilidade, resistência ao descascamento e preservação duradoura da cor, proporcionando um toque elegante e prático. Este tecido é ideal para quem procura uma solução durável e sofisticada para o controle de luz, combinando praticidade e estilo para aprimorar diversos ambientes.",
          "Particularmente adequado para atender às regras de condomínios, o tecido apresenta a cor na parte frontal, enquanto o verso é branco, oferecendo uma solução versátil que se adapta às exigências estéticas e regulamentares."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-romana/004.webp"
      },
      {
        "title": "Persiana Romana Para o Escritório ",
        "contents": [
          "O controle da luminosidade é essencial na criação de um ambiente de trabalho ideal, especialmente para aqueles que passam longas horas em frente ao computador. Destaque-se ao escolher persianas e cortinas sob medida, agregando um toque de requinte que eleva a atmosfera para um nível atrativo e ideal.",
          "As <a target=\"_blank\" href=\"/produtos/romana%20translucida\">Cortinas Romanas em tecido Translúcido</a> não apenas oferecem beleza e sofisticação, mas também desempenham uma função altamente prática em variados ambientes, seja em residências ou espaços corporativos. Explore essas opções e outros <a target=\"_blank\" href=\"/persianas/romana\">produtos disponíveis em nossa loja</a>, garantindo sua compra sem sair de casa. Descubra soluções que harmonizam estilo e funcionalidade, transformando completamente o seu espaço."
        ],
        "img": "https://d20p8exwoll05u.cloudfront.net/assets/images/more-informations/persiana-romana/005.webp"
      }
    ]
  }
]